import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  ListGroup,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/Card";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  resetPassword,
  documentGet,
} from "../../../../actions/userAction/userAction";

//img
import avatar from "../../../../assets/images/avatar-3.png";

const UserProfile = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(documentGet(user.id));
  }, []);

  const location = useLocation();
  const [user, setUser] = useState(location.state.data);
  const documentsDetails = useSelector(
    (state) => state.documentReducer.document[0]
  );

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const passport = documentsDetails?.passport,
    visa = documentsDetails?.visa,
    eid = documentsDetails?.eid,
    kyc = documentsDetails?.kyc,
    agreement = documentsDetails?.agreement;

  return (
    <>
      {/* Password Change for admins */}
      <Modal show={show} onHide={() => setShow(false)} size="l" centered={true}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => {
              values.user_id = user.id;
              delete values.confirm_password;
              dispatch(resetPassword(values));
              setShow(false);
            }}
            validationSchema={validationSchema}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Form noValidate onSubmit={handleSubmit} id="change_password">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="text"
                        name="password"
                        placeholder="New Password"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        isInvalid={!!errors.confirm_password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirm_password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" type="submit" form="change_password">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col lg="4">
            <Card>
              <div className="profile-box">
                <div className="profile-card rounded">
                  <img
                    src={user.image || avatar}
                    alt="profile-bg"
                    className="avatar-100 rounded d-block mx-auto img-fluid mb-3"
                  />
                  <h3 className="font-600 text-white text-center mb-0">
                    {user.firstname} {user.lastname}
                  </h3>
                  <p className="text-white text-center mb-5"></p>
                </div>
                <div className="pro-content rounded">
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="las la-envelope-open-text"></i>
                    </div>
                    <p className="mb-0 eml">{user.email}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="las la-phone"></i>
                    </div>
                    <p className="mb-0">{user.phone_no}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="p-icon mr-3">
                      <i className="las la-map-marked"></i>
                    </div>
                    <p className="mb-0">{user.country}</p>
                  </div>
                  {/* <div className="d-flex justify-content-center">
                    <div className="social-ic d-inline-flex rounded">
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </Card>
            {user.role == "Admin" && (
              <Button
                className="btn btn-block btn-sm btn-warning"
                onClick={() => setShow(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=""
                  width="20"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
                <span className="">Edit Password</span>
              </Button>
            )}
          </Col>

          <Col lg="8">
            <Card>
              <ListGroup as="ul">
                <ListGroup.Item as="li">
                  <div className="mr-2">
                    <h5 className="font-weight-bold">Profile Details</h5>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Email ID</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.email}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Birthday</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.dob}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.phone_no}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Alternate Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.alternate_phone_no}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Country</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.country}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">State/Region</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.state}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">City</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.city}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Address</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{user.address}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <div className="mr-2">
                    <h5 className="font-weight-bold">Document Details</h5>
                  </div>
                  <ListGroup.Item as="li">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Passport</td>
                          <td>
                            {passport ? (
                              <>
                                <a
                                  href={passport}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button variant="outline mt-2 btn-with-icon">
                                    <i className="fas fa-file-pdf"></i>
                                    Passport.pdf
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <h6>No Document Found</h6>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Aggrement</td>
                          <td>
                            {agreement ? (
                              <>
                                <a
                                  href={agreement}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button variant="outline mt-2 btn-with-icon">
                                    <i className="fas fa-file-pdf"></i>
                                    Aggrement.pdf
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <h6>No Document Found</h6>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>VISA</td>
                          <td>
                            {visa ? (
                              <>
                                <a
                                  href={visa}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button variant="outline mt-2 btn-with-icon">
                                    <i className="fas fa-file-pdf"></i>VISA.pdf
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <h6>No Document Found</h6>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>EID</td>
                          <td>
                            {eid ? (
                              <>
                                <a
                                  href={eid}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button variant="outline mt-2 btn-with-icon">
                                    <i className="fas fa-file-pdf"></i>EID.pdf
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <h6>No Document Found</h6>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>KYC</td>
                          <td>
                            {kyc ? (
                              <>
                                <a
                                  href={kyc}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button variant="outline mt-2 btn-with-icon">
                                    <i className="fas fa-file-pdf"></i>KYC.pdf
                                  </Button>
                                </a>
                              </>
                            ) : (
                              <h6>No Document Found</h6>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ListGroup.Item>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;
