import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const feedbackAdd = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}feedback`, payload)
    .then((response) => {
      if (response.status == 200) {
        // localstorage.set("userdata", JSON.stringify(payload));
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

// export const getTransactions =
//   (payload, type = "all") =>
//   (dispatch) => {
//     ajaxCall
//       .get(`${config.BASE_URL}transactions/${payload}/${type}`)
//       .then((response) => {
//         if (response.status == 200) {
//           dispatch({
//             type: actionTypes.GET_TRANSACTIONS,
//             payload: response.data.data,
//           });
//           return response.data.data;
//         }
//       })
//       .catch((error) => {
//         dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
//       });
//   };
