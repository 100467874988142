import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { documentUpload } from "../actions/userAction/userAction";

const DocUpload = (props) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("Choose File");
  const [file, setFile] = useState("");

  return (
    <div class="input-group">
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id={props.id}
          name={props.name}
          aria-describedby="inputGroupFileAddon01"
          onChange={(e) => {
            setFileName(e.target.files[0].name);
            setFile(e.target.files[0]);
          }}
        />
        <label class="custom-file-label" for="inputGroupFile01">
          {fileName}
        </label>
      </div>
      <Button
        variant="info"
        onClick={() => {
          // Create an object of formData
          const formData = new FormData();

          // Update the formData object
          formData.append(props.id, file);
          formData.append("id", props.user_id);
          dispatch(documentUpload(formData));
        }}
      >
        Upload
      </Button>
    </div>
  );
};

export default DocUpload;
