import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Tab,
  Nav,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import AccountContent from "../../../components/AccountContent";
import Chart from "react-apexcharts";
// img
import user1 from "../../../assets/images/user/1.jpg";
import AccountItem from "../../../components/AccountItem";

const Customerview = () => {
  const [active, setActive] = useState("1");
  const chart1 = {
    series: [
      {
        data: [91, 82, 90, 88, 105, 99],
      },
    ],
    options: {
      chart: {
        //   height: 265,
        //   type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        labels: {
          offsetY: 0,
          minWidth: 20,
          maxWidth: 20,
        },
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "June"],
        labels: {
          minHeight: 22,
          maxHeight: 22,
          style: {
            fontSize: "12px",
          },
        },
      },
    },
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Customers</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Customer View
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/customer"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Customer View
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={user1}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">Kate Smith</h5>
                          <p className="mb-0 mt-1 text-muted">
                            Botsford and Sons
                          </p>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  <Row className="mt-3">
                    {/* <Col xs="6" className="text-center mb-2">
                                    <Button variant="btn btn-block btn-sm btn-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                        </svg>
                                        <span className="">Message</span>
                                    </Button>
                                </Col> */}
                    <Col xs="6" className="text-center">
                      <Button variant="btn btn-block btn-sm btn-secondary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className=""
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                        <span className="">Edit Profile</span>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Email ID</p>
                        </td>
                        <td>
                          <p className="mb-0 ">kate@yahoo.com</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Birthday</p>
                        </td>
                        <td>
                          <p className="mb-0 ">01 Feb 1990</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">+99 8756214524</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Country</p>
                        </td>
                        <td>
                          <p className="mb-0 ">USA</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">State/Region</p>
                        </td>
                        <td>
                          <p className="mb-0 ">West Virginia</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Address</p>
                        </td>
                        <td>
                          <p className="mb-0 ">Baker Street, no. 7</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="p-3">
                  <h5 className="font-weight-bold pb-2">Account Details</h5>
                  {/* <ul>
                    <AccountItem
                      id="1"
                      number="123456"
                      type="Debt"
                      onClick={() => setActive("1")}
                    />
                    <AccountItem
                      id="2"
                      number="76757"
                      type="Debt"
                      onClick={() => setActive("2")}
                    />
                  </ul> */}
                  {/* <div className="table-responsive">
                    <table className="table data-table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-muted">
                          <th scope="col">Account Number</th>
                          <th scope="col">Account Type </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  {/* <Chart
                    options={chart1.options}
                    series={chart1.series}
                    type="bar"
                    height="250px"
                  /> */}
                  <div className="table-responsive table-hover">
                    <table className="table data-table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-muted">
                          <th scope="col">Account Number</th>
                          <th scope="col">Account Type </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <AccountItem
                            id="1"
                            number="123456"
                            type="Debt"
                            onClick={() => setActive("1")}
                          />
                          <AccountItem
                            id="2"
                            number="98790"
                            type="Equity"
                            onClick={() => setActive("2")}
                          />
                        </tr>
                        {/* <tr>
                          <td>1234567</td>
                          <td>Debt</td>
                        </tr>
                        <tr>
                          <td>1234567</td>
                          <td>Debt</td>
                        </tr>
                        <tr>
                          <td>1234567</td>
                          <td>Debt</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col lg="8">
            {/* <Card>
              <Card.Body className="p-0">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h5>Invoice List</h5>
                </div>
              </Card.Body>
            </Card> */}
            {/* <Tab.Container defaultActiveKey="first"> */}
            <AccountContent
              id="1"
              number="2345342"
              active={active === "1" ? true : false}
            />
            <AccountContent
              id="2"
              number="9779304"
              active={active === "2" ? true : false}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Customerview;
