import React, { useState } from "react";

import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";

import footerLogo from "../../../assets/images/hedge-logo.jpeg";
import logo from "../../../assets/images/logo.png";

import feedbackWelcome from "../../../assets/images/feedbackWelcome.jpeg";

//import css
import "./feedback.css";
import FeedbackView from "../../../components/partials/backend/feedbackView";
let active = "ques1";
function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const ClientFeedback = (props) => {
  const [feedbackStart, setFeedbackStart] = useState(false);

  const feedbackQuestion = {
    ques1: "Please enter your name",
    ques2: "Please enter your email",
    ques3: "What are the top reasons that made you choose us?",
    ques4: "How would you rate the quality of our products?",
    ques5: "Are you getting payouts on time ?",
    ques6: "How satisfied are you with the competency of our customer support?",
    ques7:
      "On a scale of 0-10, how likely are you to recommend Hedge and Sachs to a friend or colleague?",
    ques8:
      "Thank you for your valuable feedback. Please press the submit button to submit the response",
  };
  const [tmpans, setTempans] = useState("");
  const [answer, setAnswer] = useState({
    ques1: "",
    ques2: "",
    ques3: "",
    ques4: "",
    ques5: "",
    ques6: "",
    ques7: "",
    ques8: "",
  });

  return (
    <>
      <div className="feedback-container">
        {!feedbackStart && (
          <Card>
            <Card.Img variant="top" src={feedbackWelcome} alt="#" />
            <Card.Body>
              <Card.Title as="h4">Hii, Welcome</Card.Title>
              <Card.Text>
                We love to have your feedback. Your valuable feedback will help
                us improve our services and inevitably make your expierence at
                Hedge&Sachs great.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Card.Text className="text-center" as="h4">
                Ready to start ?
              </Card.Text>
              <Card.Text as="h6" className="text-center">
                Please press the start button
              </Card.Text>
              <Button
                block
                variant="primary"
                onClick={() => setFeedbackStart(true)}
              >
                Start
              </Button>
            </Card.Body>
          </Card>
        )}
        {feedbackStart && (
          <Card>
            <Card.Header className="chat-content-header feedback-header">
              <div className="d-flex align-items-center">
                <h4 style={{ color: "white" }}>Client Satisfaction Survey</h4>
              </div>
            </Card.Header>
            <Card.Body
              className="msg-content feedback-body"
              id={`user-${props.id}-chat-content`}
            >
              <div className="msg-list">
                {/* Typing animation */}
                {/* <div className="feedback-bot typing-bot">
                  <img
                    src={logo}
                    style={{
                      height: "42px",
                      width: "42px",
                      borderRadius: "50%",
                      border: "3px solid green",
                    }}
                  />
                  <div className="single-msg typing-bot">
                    <div className="typing">
                      <div className="typing__dot"></div>
                      <div className="typing__dot"></div>
                      <div className="typing__dot"></div>
                    </div>
                  </div>
                </div> */}
                {/* Typing animation */}
                {Object.keys(feedbackQuestion).map((fq, index) => {
                  active = !answer[fq] && answer[`ques${index}`] ? fq : active;
                  return (
                    <FeedbackView
                      question={feedbackQuestion}
                      answer={answer}
                      activeques={fq}
                      index={index + 1}
                      logo={logo}
                      nextQuestion={(tmpans) => {
                        setAnswer({ ...answer, [active]: tmpans });
                        setTempans("");
                      }}
                    />
                  );
                })}
              </div>
            </Card.Body>
            <Card.Footer>
              {["ques3", "ques4", "ques5", "ques6", "ques8"].includes(
                active
              ) ? (
                ""
              ) : (
                <form id="feedback-form">
                  <div className="input-group input-group-lg">
                    {["ques1", "ques2", "ques7"].includes(active) && (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter here..."
                        value={tmpans}
                        onChange={(e) => {
                          setTempans(e.target.value);
                        }}
                      />
                    )}
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="input-group-text chat-icon"
                        id={`basic-addon${props.id}`}
                        onClick={() => {
                          if (tmpans) {
                            setAnswer({ ...answer, [active]: tmpans });
                            setTempans("");
                          }
                        }}
                      >
                        <i className="lab la-telegram-plane"></i>
                      </button>
                    </div>
                  </div>
                </form>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5%",
                }}
              >
                <img
                  src={footerLogo}
                  style={{ width: "80%", margin: "auto" }}
                />
              </div>
            </Card.Footer>
          </Card>
        )}
      </div>
    </>
  );
};

export default connect(mapStateToProps)(ClientFeedback);
