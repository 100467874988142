import React from "react";
import FooterStyle from "../components/partials/backend/FooterStyle/FooterStyle";
import HeaderStyle1 from "../components/partials/backend/HeaderStyle/HeaderStyle1";

// Router Component
import SidebarCustom from "../components/partials/backend/SidebarCustom/SidebarCustom";
import { useLocation } from "react-router-dom";
import UserAdd from "../views/backend/App/UserManagement/UserAdd";
import UserProfile from "../views/backend/App/UserManagement/UserProfile";

import Dashbord from "../views/backend/Main/Dashbord";
import Customer from "../views/backend/Main/Customer";
import Customerview from "../views/backend/Main/Cutomerview";
import CustomerDetails from "../views/backend/Main/CustomerDetails";
import Employee from "../views/backend/Main/Employee";
import Employeeview from "../views/backend/Main/Employeeview";
import Employeeedit from "../views/backend/Main/Employeeedit";
import ClientFeedback from "../views/backend/Main/ClientFeedback";
import EquityAccount from "../views/backend/Main/EquityAccount";
import DebtAccount from "../views/backend/Main/DebtAccount";
import PankajChat from "../views/backend/Main/PankajChat";
// Client
import ClientDashboard from "../views/backend/Client/ClientDashboard";
import { useDispatch } from "react-redux";
import { getCurrentUserAccount } from "../actions/accountAction/accountAction";
//Employee
import EmployeeDashboard from "../views/backend/Employee/EmployeeDashboard";

const PankajLayout = () => {
  let path = useLocation().pathname;
  path = path.split("/")[1];

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userdata"));
  if (userData.role == "Customer") {
    dispatch(getCurrentUserAccount());
  }

  const getComponent = (path) => {
    switch (path) {
      case "user-profile":
        return <UserProfile />;
      case "user-add":
        return <UserAdd />;
      case "customer":
        return <Customer />;
      case "customer-view":
        return <Customerview />;
      case "customer-details":
        return <CustomerDetails />;
      case "account-history-debt":
        return <DebtAccount />;
      case "account-history-equity":
        return <EquityAccount />;
      case "employee":
        return <Employee />;
      case "employee-view":
        return <Employeeview />;
      case "employee-edit":
        return <Employeeedit />;
      case "clientfeedback":
        return <ClientFeedback />;
      case "chat":
        return <PankajChat />;
      default:
        if (userData && userData.role == "Customer") {
          return <ClientDashboard user={userData} />;
        } else if (userData && userData.role == "Employee") {
          return <EmployeeDashboard user={userData} />;
        }
        return <Dashbord />;
    }
  };
  return (
    <>
      <div className="wrapper">
        <HeaderStyle1 user={userData} />
        <SidebarCustom user={userData} />
        <div className="content-page">{getComponent(path)}</div>
      </div>
      <FooterStyle />
    </>
  );
};

export default PankajLayout;
