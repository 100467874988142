import { Switch, Route, Link, Redirect } from "react-router-dom";
import SignIn from "../views/backend/Auth/SignIn";
import PankajLayout from "../layouts/PankajLayout";
import PrivateRoute from "./PrivateRoute";
import ClientFeedback from "../views/backend/Main/ClientFeedback";
import PankajChat from "../views/backend/Main/PankajChat";

const LayoutsRoute = (props) => {
  return (
    <Switch>
      <PrivateRoute path="/" component={PankajLayout} exact />

      {/* App */}
      <PrivateRoute path="/user-profile/:id" component={PankajLayout} exact />
      <PrivateRoute path="/user-add/edit/:id" component={PankajLayout} exact />
      <PrivateRoute path="/user-add" component={PankajLayout} exact />

      {/*Customer*/}
      <PrivateRoute path="/customer" component={PankajLayout} exact />
      <PrivateRoute path="/customer-view" component={PankajLayout} exact />
      <PrivateRoute
        path="/customer-details/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/customer-edit/:id" component={PankajLayout} exact />
      <PrivateRoute
        path="/account-history-equity/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute
        path="/account-history-debt/:id"
        component={PankajLayout}
        exact
      />
      <PrivateRoute path="/customer-add" component={PankajLayout} exact />

      {/* Employee */}
      <PrivateRoute path="/employee" component={PankajLayout} exact />
      <PrivateRoute path="/employee-view/:id" component={PankajLayout} exact />
      <PrivateRoute path="/employee-edit" component={PankajLayout} exact />

      {/* Feedback */}
      <Route path="/clientfeedback" component={ClientFeedback} exact />
      <Route path="/chat" component={PankajChat} exact />

      {!props.auth.isAuthenticated && (
        <Route path="/sign-in" component={SignIn} />
      )}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default LayoutsRoute;
