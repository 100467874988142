import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const getEmployeeClient = (payload) => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}users/get_emp_customer/${payload}`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.GET_EMP_USERS,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const getUserType = (payload) => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}users/get_user_types/${payload}`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.SUCCESS_MESSAGE,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const updateCustomerEmp = (id, payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}users/update_customer_emp/${id}`, { payload })
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.SUCCESS_MESSAGE,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};
