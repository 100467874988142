import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import consitentreturn from "../../../assets/images/rating/consistentreturns.png";
import easeofuse from "../../../assets/images/rating/easeofuse.png";
import higherprofit from "../../../assets/images/rating/higherprofit.png";
import risks from "../../../assets/images/rating/risks.png";
import { Rating } from "react-simple-star-rating";
import {
  MdOutlineSentimentDissatisfied,
  MdOutlineSentimentNeutral,
  MdOutlineSentimentSatisfied,
  MdOutlineSentimentVeryDissatisfied,
  MdOutlineSentimentVerySatisfied,
} from "react-icons/md";

import Spinner from "../../../views/backend/pages/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { feedbackAdd } from "../../../actions/feedbackAction/feedbackAction";

const customIcons = [
  { icon: <MdOutlineSentimentDissatisfied size={50} /> },
  { icon: <MdOutlineSentimentNeutral size={50} /> },
  { icon: <MdOutlineSentimentSatisfied size={50} /> },
  { icon: <MdOutlineSentimentVeryDissatisfied size={50} /> },
  { icon: <MdOutlineSentimentVerySatisfied size={50} /> },
];

function FeedbackView(props) {
  // const [istyping, setIstyping] = useState(true);
  // const [multiselect, setMultiselect] = useState([]);
  const [satisfactionValue, setSatisfactionValue] = useState(0);
  const multiselect = [];
  const showTyping = () => {
    // <div className="typing">
    //   <div className="typing__dot"></div>
    //   <div className="typing__dot"></div>
    //   <div className="typing__dot"></div>
    // </div>
  };
  const handleDivSelect = () => {
    let element = document.getElementById("multiple-select");
    let childs = element.children;
    for (let i = 0; i < childs.length; i++) {
      let child = childs[i];
      if (child.classList.contains("active")) {
        // setMultiselect([child.id]);
        multiselect.push(child.id);
        // console.log(multiselect);
      }
    }
  };
  const handleShowNone = () => {
    let element = document.getElementById("multiple-select");
    let childs = element.children;
    document.getElementById("nextButton").style.display = "none";
    for (let i = 0; i < childs.length; i++) {
      let child = childs[i];
      child.style.display = "none";
    }
  };

  // let newmultiselect = [...new Set(multiselect)];
  const [rating, setRating] = useState(0); // initial rating value

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    props.nextQuestion(rate);
  };

  const handleyes = (e) => {
    props.nextQuestion(e.target.value);
  };

  const satisfaction = (rate) => {
    setSatisfactionValue(rate);
    props.nextQuestion(rate);
  };

  const dispatch = useDispatch();

  const location = useLocation();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [loading]);

  if (loading) return <Spinner />;

  const submitValues = (answer) => {
    //API Call
    console.log(answer);
    let obj = Object.assign({}, answer);
    delete obj.ques8;
    helloHandeler();
    // console.log(
    //   "🚀 ~ file: feedbackView.js ~ line 80 ~ submitValues ~ obj",
    //   obj
    // );
    dispatch(feedbackAdd(answer));
    window.location.replace("https://hedgeandsachs.com/");
  };

  const helloHandeler = () => {
    setLoading(!loading);
    setTimeout(() => {
      setLoading(!loading);
      setShow(!show);
    }, 4000);
  };

  const { question, answer, activeques, index, logo } = props;
  let ans = index == 1 ? true : answer[`ques${index - 1}`];
  return (
    <>
      {!show && ((!answer[activeques] && ans) || answer[activeques]) && (
        <div className={`feedback-bot ${activeques}`}>
          <img
            src={logo}
            style={{
              height: "42px",
              width: "42px",
              borderRadius: "50%",
              border: "3px solid green",
            }}
          />
          <div className={`single-msg bot ${activeques}`}>
            {/* {setTimeout(() => showTyping, 100)} */}
            <div className="single-msg-content">
              <div className="msg-detail">
                <span>{question[activeques]}</span>
              </div>
            </div>
          </div>
          {activeques == "ques3" && (
            <>
              <div
                className={`single-msg bot ${activeques}`}
                style={{ marginTop: "5%", backgroundColor: "#eaf1ff" }}
                id="multiple-select"
              >
                <div
                  className="select-picture"
                  id="Consistent-Returns"
                  onClick={(e) => {
                    document
                      .getElementById("Consistent-Returns")
                      .classList.toggle("active");
                    // handleDivSelect(e);
                  }}
                >
                  <div className="image-holder">
                    <img
                      src={consitentreturn}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p>Consistent Returns</p>
                  </div>
                </div>
                <div
                  className="select-picture"
                  id="Ease-Of-Use"
                  onClick={(e) => {
                    document
                      .getElementById("Ease-Of-Use")
                      .classList.toggle("active");
                    // handleDivSelect(e);
                  }}
                >
                  <div className="image-holder">
                    <img
                      src={easeofuse}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p>Ease Of Use</p>
                  </div>
                </div>
                <div
                  className="select-picture"
                  id="Higher-Profit"
                  onClick={(e) => {
                    document
                      .getElementById("Higher-Profit")
                      .classList.toggle("active");
                    // handleDivSelect(e);
                  }}
                >
                  <div className="image-holder">
                    <img
                      src={higherprofit}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p>Higher Profits</p>
                  </div>
                </div>
                <div
                  className="select-picture"
                  id="Minimum-Risk"
                  onClick={(e) => {
                    document
                      .getElementById("Minimum-Risk")
                      .classList.toggle("active");
                    // handleDivSelect(e);
                  }}
                >
                  <div className="image-holder">
                    <img
                      src={risks}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p>Minimum Risks</p>
                  </div>
                </div>
                {/* <div
                  className="select-picture"
                  id="All-of-the-above"
                  onClick={(e) => {
                    document
                      .getElementById("All-of-the-above")
                      .classList.add("active");
                    document
                      .getElementById("Consistent-Returns")
                      .classList.add("active");
                    document
                      .getElementById("Ease-Of-Use")
                      .classList.add("active");
                    document
                      .getElementById("Higher-Profit")
                      .classList.add("active");
                    document
                      .getElementById("Minimum-Risk")
                      .classList.add("active");
                    handleDivSelect(e);
                  }}
                >
                  <div className="image-holder">
                    <img
                      src={consitentreturn}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div>
                    <p>All of the above</p>
                  </div>
                </div> */}
              </div>
              <div className="next-box">
                <Button
                  type="submit"
                  variant="primary"
                  id="nextButton"
                  onClick={() => {
                    handleDivSelect();
                    handleShowNone();
                    props.nextQuestion(multiselect);
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {activeques == "ques4" && (
            <>
              <div
                className="single-msg bot ques3"
                style={{ marginTop: "5%", backgroundColor: "#eaf1ff" }}
              >
                <Rating
                  onClick={handleRating}
                  ratingValue={rating} /* Available Props */
                  readonly={rating > 0}
                />
              </div>
            </>
          )}
          {activeques == "ques5" && (
            <>
              <div
                className="single-msg bot ques3"
                style={{ marginTop: "5%", backgroundColor: "#eaf1ff" }}
              >
                <Button
                  variant="outline-primary rounded-pill mt-0 btn-with-icon"
                  type="submit"
                  value="Yes"
                  onClick={(e) => handleyes(e)}
                >
                  Yes
                </Button>
                <Button
                  variant="outline-primary rounded-pill mt-0 btn-with-icon"
                  type="submit"
                  value="No"
                  onClick={(e) => handleyes(e)}
                >
                  No
                </Button>
              </div>
            </>
          )}
          {activeques == "ques6" && (
            <>
              <div
                className="single-msg bot ques3"
                style={{ marginTop: "5%", backgroundColor: "#eaf1ff" }}
              >
                <Rating
                  onClick={satisfaction}
                  ratingValue={satisfactionValue}
                  customIcons={customIcons}
                  readonly={satisfactionValue > 0}
                />
              </div>
            </>
          )}
          {activeques == "ques8" && (
            <>
              <div
                className="single-msg bot ques3"
                style={{ marginTop: "5%", backgroundColor: "#eaf1ff" }}
              >
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => submitValues(answer)}
                >
                  Submit{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      {answer[activeques] && (
        <div className="single-msg user">
          <div className="triangle-topright single-msg-shap"></div>
          <div className="single-msg-content user">
            <div className="msg-detail">
              <span>{answer[activeques]}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackView;
