import React, { useEffect, useState, useRef } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Card from "../../../../components/Card";

import { Multiselect } from "multiselect-react-dropdown";

//datepicker
import Datepickers from "../../../../components/Datepicker";

import { useDispatch, useSelector } from "react-redux";
import { userAdd, userUpdate } from "../../../../actions/userAction/userAction";
import { getUsers } from "../../../../actions/userAction/userAction";

const UserAdd = (props) => {
  // get customer data
  const location = useLocation();
  const [customer, setCustomer] = useState(location.state.data);
  const [userImg, setUserImg] = useState("");
  const [displayImg, setDisplayImg] = useState("");
  const isEdit = !!customer;

  const history = useHistory();

  const validationSchema = Yup.object().shape({
    isEdit: Yup.boolean(),
    email: Yup.string().email("Invalid email").required("Required"),
    firstname: Yup.string()
      .min(3, "Too Short")
      .max(20)
      .matches(/^[aA-zZ\s]+$/, "Only characters are allowed ")
      .required("Required"),
    lastname: Yup.string()
      .min(3, "Too Short")
      .max(20)
      .matches(/^[aA-zZ\s]+$/, "Only characters are allowed ")
      .required("Required"),
    phone_no: Yup.string()
      .required("Required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .max(12),
    alternate_phone_no: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .max(12),
    dob: Yup.date().required("Required"),
    gender: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    password: Yup.string().when("isEdit", {
      is: false,
      then: Yup.string().required("Required"),
    }),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userReducer.users);

  const refClients = userList.map((item) => ({
    userID: item.id,
    name: item.firstname + item.lastname,
  }));

  const multiselectRef = React.createRef();
  useEffect(() => {
    dispatch(getUsers("Customer"));
  }, []);

  const handleImage = (e) => {
    setUserImg(e.target.files[0]);
    setDisplayImg(URL.createObjectURL(e.target.files[0]));
  };

  // const [userImg, setUserImg] = useState("");

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-end">
              {/* <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Customers</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {isEdit ? "Edit User" : "Add User"}
                    </li>
                  </ol>
                </nav>
              </div> */}
              <Link
                to="/"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {isEdit ? "Edit User" : "Add User"}
            </h4>
          </Col>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => {
              values.phone_no = values.mob_select_country + values.phone_no;
              values.alternate_phone_no =
                values.alter_mob_select_country + values.alternate_phone_no;

              if (isEdit) {
                values.id = customer.id;
                delete obj.password;
              }

              let obj = Object.assign({}, values);

              delete obj.confirm_password;
              delete obj.alter_mob_select_country;
              delete obj.mob_select_country;

              if (userImg) {
                values.image = userImg;
                obj.image = userImg;
              }
              if (isEdit) {
                dispatch(userUpdate(obj));
              } else {
                dispatch(userAdd(values));
              }
              if (obj.role === "Customer") {
                history.push("/customer");
              } else {
                history.push("/employee");
              }
            }}
            enableReinitialize={true}
            initialValues={{
              role: isEdit ? customer.role : "",
              firstname: isEdit ? customer.firstname : "",
              lastname: isEdit ? customer.lastname : "",
              dob: isEdit ? customer.dob : "",
              email: isEdit ? customer.email : "",
              phone_no: isEdit
                ? customer.phone_no.substring(
                    customer.phone_no.indexOf("-") + 1
                  )
                : "",
              alternate_phone_no: isEdit
                ? customer.alternate_phone_no.substring(
                    customer.alternate_phone_no.indexOf("-") + 1
                  )
                : "",
              mob_select_country: isEdit
                ? customer.phone_no.split("-", 1) + "-"
                : "",
              alter_mob_select_country: isEdit
                ? customer.alternate_phone_no.split("-", 1).pop() + "-"
                : "",
              country: isEdit ? customer.country : "",
              city: isEdit ? customer.city : "",
              address: isEdit ? customer.address : "",
              gender: isEdit ? customer.gender : "",
              state: isEdit ? customer.state : "",
              password: "",
              confirm_password: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Col lg="12">
                <Card>
                  <Card.Body>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col md="4">
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group mb-3">
                              <label htmlFor="">User Role</label>
                              <Field
                                as="select"
                                className="form-control"
                                name="role"
                              >
                                <option>Select Role</option>
                                <option value="Employee">Employee</option>
                                <option value="Customer">Customer</option>
                                <option value="CommissionedEmployee">
                                  Commissioned Employee
                                </option>
                                <option value="Admin">Admin</option>
                              </Field>
                            </div>
                          </div>
                        </Col>
                        {values.role == "CommissionedEmployee" && (
                          <Col md="4">
                            <div className="col-md-12 col-sm-12">
                              <div className="form-group mb-3">
                                <label htmlFor="">
                                  Select Reference Client
                                </label>
                                <Multiselect
                                  id="referenceClient"
                                  ref={multiselectRef}
                                  options={refClients}
                                  displayValue="name"
                                />
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="9">
                          <Card.Header>
                            <Card.Header.Title>
                              <h4 className="card-title">
                                {isEdit
                                  ? "Edit User Information"
                                  : "New User Information"}
                              </h4>
                            </Card.Header.Title>
                          </Card.Header>
                          <Card.Body>
                            <div className="new-user-info">
                              <Row>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">First Name</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.firstname && errors.firstname
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="First Name"
                                      name="firstname"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="firstname"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Last Name</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.lastname && errors.lastname
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Last Name"
                                      name="lastname"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="lastname"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Email</label>
                                    <Field
                                      type="email"
                                      className={`form-control ${
                                        touched.email && errors.email
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Email"
                                      name="email"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="email"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Birthday</label>
                                    <Field
                                      type="date"
                                      className={`form-control ${
                                        touched.dob && errors.dob
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Birthday"
                                      name="dob"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="dob"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Gender</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.gender && errors.gender
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter First Name"
                                      name="gender"
                                    >
                                      <option>Select Gender</option>
                                      <option value="MALE">Male</option>
                                      <option value="FEMALE">Female</option>
                                      <option value="OTHER">Other</option>
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="gender"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <label htmlFor="">Mobile Number</label>
                                  <div className="mb-6 input-group form-group">
                                    <div
                                      className="input-group-prepend"
                                      style={{ marginRight: "5px" }}
                                    >
                                      <Field
                                        as="select"
                                        className="form-control"
                                        name="mob_select_country"
                                      >
                                        <option>Country</option>
                                        <option value="+91-">
                                          INDIA (+91)
                                        </option>
                                        <option value="+971-">
                                          UAE (+971)
                                        </option>
                                        <option value="+1-">USA (+1)</option>
                                        <option value="+1-">SA (+27)</option>
                                      </Field>
                                    </div>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.phone_no && errors.phone_no
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Phone No"
                                      name="phone_no"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="phone_no"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <label htmlFor="">
                                    Alternate Mobile Number
                                  </label>
                                  <div className="mb-6 input-group form-group">
                                    <div
                                      className="input-group-prepend"
                                      style={{ marginRight: "5px" }}
                                    >
                                      <Field
                                        as="select"
                                        className="form-control"
                                        name="alter_mob_select_country"
                                      >
                                        <option>Country</option>
                                        <option value="+91-">
                                          INDIA (+91)
                                        </option>
                                        <option value="+971-">
                                          UAE (+971)
                                        </option>
                                        <option value="+1-">USA (+1)</option>
                                        <option value="+1-">SA (+27)</option>
                                      </Field>
                                    </div>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.alternate_phone_no &&
                                        errors.alternate_phone_no
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Phone No"
                                      name="alternate_phone_no"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="alternate_phone_no"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-6">
                                    <label htmlFor="">Address</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.address && errors.address
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Address"
                                      name="address"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="address"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="form-group mb-3">
                                    <label htmlFor="">Country</label>
                                    <Field
                                      as="select"
                                      className={`form-control ${
                                        touched.country && errors.country
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name="country"
                                    >
                                      <option>Select Country</option>
                                      <option value="India">India</option>
                                      <option value="USA">USA</option>
                                      <option value="UAE">UAE</option>
                                      <option value="UK">UK</option>
                                      <option value="SA">South Africa</option>
                                    </Field>
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="country"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-6">
                                    <label htmlFor="">Town/City</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.city && errors.city
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Address"
                                      name="city"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="city"
                                      component="span"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form-group mb-6">
                                    <label htmlFor="">State/Region</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        touched.state && errors.state
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Address"
                                      name="state"
                                    />
                                    <ErrorMessage
                                      className="invalid-feedback"
                                      name="state"
                                      component="span"
                                    />
                                  </div>
                                </div>
                              </Row>
                              {!isEdit && (
                                <>
                                  <hr />
                                  <h5 className="mb-3">Security</h5>
                                  <Row>
                                    <Form.Group as={Col} md="6">
                                      <Form.Label>Password:</Form.Label>
                                      <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        isInvalid={!!errors.password}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                      <Form.Label>Repeat Password:</Form.Label>
                                      <Form.Control
                                        type="password"
                                        placeholder="Repeat Password "
                                        name="confirm_password"
                                        onChange={handleChange}
                                        isInvalid={!!errors.confirm_password}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.confirm_password}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Row>
                                </>
                              )}
                              <hr />
                              <h5 className="mb-3">Profile Image</h5>
                              <Row>
                                <input
                                  type="file"
                                  id="userImg"
                                  name="userImage"
                                  onChange={(e) => handleImage(e)}
                                />
                                {isEdit && (
                                  <img
                                    src={customer.image}
                                    height="100"
                                    width="100"
                                  ></img>
                                )}
                                {displayImg && (
                                  <img
                                    src={displayImg}
                                    height="100"
                                    width="100"
                                  ></img>
                                )}
                              </Row>
                              <Button
                                type="submit"
                                variant="btn btn-primary btn-block mt-2"
                              >
                                {isEdit ? "Edit User" : "Add User"}
                              </Button>
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
};

export default UserAdd;
