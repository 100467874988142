import { USER_LOADED, AUTH_ERROR } from "../../constants/actionTypes";
import * as localStorage from "../../common/localStorage";

const initialState = {
  token: localStorage.get("token") || null,
  isAuthenticated: localStorage.get("token") ? true : false,
  userData: localStorage.get("userdata")
    ? JSON.parse(localStorage.get("userdata"))
    : null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        userData: action.payload.user,
      };
    case AUTH_ERROR:
      console.log("hdghwjebjwhdwwefwefwefwe");
      localStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
