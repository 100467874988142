import { combineReducers } from "redux";
import Mode from "./mode/index";
import authReducer from "./reducers/authReducer";
import toasterReducer from "./reducers/toasterReducer";
import userReducer from "./reducers/userReducer";
import accountReducer from "./reducers/accountReducer";
import transactionReducer from "./reducers/transactionReducer";
import employeeReducer from "./reducers/employeeReducer";
import documentReducer from "./reducers/documentReducer";
import feedbackReducer from "./reducers/feedbackReducer";
export default combineReducers({
  mode: Mode,
  authReducer,
  toasterReducer,
  userReducer,
  accountReducer,
  employeeReducer,
  documentReducer,
  transactionReducer,
  feedbackReducer,
});
