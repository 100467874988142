import React, { useState, useEffect } from "react";

import { Formik } from "formik";

import * as Yup from "yup";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";

import moment from "moment";

import { useDispatch } from "react-redux";
import {
  transactionAdd,
  transactionDelete,
} from "../../../actions/transactionAction/transAction";
//datepicker
import Datepickers from "../../../components/Datepicker";

const Returns = (props) => {
  const dispatch = useDispatch();

  const [tempTD, setTempTD] = useState([...props.transactionDetails]);

  useEffect(() => {
    setTempTD(props.transactionDetails);
  }, [props.transactionDetails]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateFilter, setDateFilter] = useState(false);

  const handleFilter = () => {
    let temp = [...props.transactionDetails];
    if (!dateFilter) {
      temp = props.transactionDetails.filter((item) => {
        return (
          fromDate &&
          moment(new Date(fromDate)) <=
            moment(new Date(item.transaction_date)) &&
          toDate &&
          moment(new Date(toDate)) >= moment(new Date(item.transaction_date))
        );
      });
    }
    setTempTD(temp);
  };

  const filterDate = () => {
    if (fromDate || toDate) {
      setDateFilter(true);
    }
  };

  let index = 1;
  let totalReturn = 0;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setDisabled(true);
  };
  const handleShow = () => setShow(true);
  const {
    accountDetails: { currency, account_balance, account_type, payout },
  } = props;
  let currencySymbol =
    currency == "USD"
      ? "$"
      : currency == "AED"
      ? "AED"
      : currency == "INR"
      ? "₹"
      : "€";
  let transaction_amount =
    account_type == "Equity" ? 0 : (payout * account_balance) / 100;

  const [disabled, setDisabled] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteTransaction, setDeleteTransaction] = useState("");
  const handleDeleteShow = (item) => {
    setShowDelete(true);
    setDeleteTransaction(item);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
    setIsEdit(false);
  };
  const handleDeleteSubmit = () => {
    dispatch(transactionDelete(deleteTransaction.id, props.account, "all"));
    setShowDelete(false);
    setIsEdit(false);
  };

  let payoutArr = [];
  let totalDeposit = 0;
  tempTD
    .slice()
    .reverse()
    .map((item) => {});

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title as="h5">Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => {
              values.account_id = props.account;
              values.transaction_type = "Returns";
              {
                account_type == "Equity" &&
                  (values.transaction_amount =
                    +values.customer_share + +values.company_share);
              }
              dispatch(transactionAdd(values, "all"));
              setShow(false);
              setDisabled(true);
            }}
            initialValues={{
              transaction_date: "",
              customer_share: "",
              company_share: "",
              transaction_amount: `${transaction_amount}`,
            }}
          >
            {({ handleSubmit, handleChange }) => (
              <Form noValidate onSubmit={handleSubmit} id="add_payout">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Payout Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="transaction_date"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {account_type == "Equity" && (
                    <>
                      <Col>
                        <Form.Group>
                          <Form.Label>Company Share</Form.Label>
                          <Form.Control
                            type="text"
                            name="company_share"
                            // defaultValue={transaction_amount}
                            onChange={handleChange}
                            // disabled={disabled}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Client Share</Form.Label>
                          <Form.Control
                            type="text"
                            name="customer_share"
                            // defaultValue={transaction_amount}
                            onChange={handleChange}
                            // disabled={disabled}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  <Col>
                    <Form.Group>
                      <Form.Label>Payout Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="transaction_amount"
                        defaultValue={transaction_amount}
                        onChange={handleChange}
                        disabled={disabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Currency</Form.Label>
                      <Form.Control
                        type="text"
                        defaultValue={currency}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setDisabled(false)}>
            Edit
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="add_payout">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the transaction?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleDeleteSubmit()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="table" className="table-editable">
        {props.user.role == "Admin" && (
          <>
            <span className="table-add float-right mb-3 mr-2">
              <button
                onClick={() => handleShow()}
                className="btn btn-sm bg-primary"
              >
                <i className="ri-add-fill">
                  <span className="pl-1">Add New</span>
                </i>
              </button>
            </span>
            <span className="table-add float-right mb-3 mr-2">
              <button
                onClick={() => setIsEdit(true)}
                className="btn btn-sm bg-warning"
              >
                <i className="ri-edit-fill">
                  <span className="pl-1">Edit Table</span>
                </i>
              </button>
            </span>
          </>
        )}
        <div
          className="form-group vanila-daterangepicker d-flex flex-row"
          id="date_filter"
          style={{ marginTop: "3%", marginBottom: "3%" }}
        >
          <div className="date-icon-set">
            <Datepickers
              className="vanila-datepicker"
              names="start"
              placeholder="From Date"
              value={fromDate}
              onBlur={(e) => setFromDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span className="flex-grow-0">
            <span className="btn">To</span>
          </span>
          <div className="date-icon-set">
            <Datepickers
              names="end"
              placeholder="To Date"
              value={toDate}
              onBlur={(e) => setToDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span
            className="table-add float-right mb-3 mr-2"
            style={{
              marginLeft: "30px",
            }}
          >
            {!dateFilter ? (
              <button
                onClick={() => {
                  filterDate();
                  handleFilter();
                }}
                className="btn btn-sm bg-warning"
              >
                <span className="pl-1">Filter</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  setDateFilter(false);
                  setToDate("");
                  setFromDate("");
                  handleFilter();
                }}
                className="btn btn-sm bg-success"
              >
                <span className="pl-1">Reset</span>
              </button>
            )}
          </span>
        </div>
        <table className="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>No.</th>
              <th>Date\Time</th>
              <th>Amount</th>
              {(account_type == "Equity" && (
                <>
                  <th>Client Share</th>
                  <th>Company Share</th>
                  <th>Payout</th>
                </>
              )) || <th>Currency</th>}

              {isEdit && <th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {tempTD.map((item) => {
              let payoutPercent = 0;
              let totalDeposit = 0;
              totalReturn =
                totalReturn +
                (item.transaction_type === "Returns"
                  ? item.transaction_amount
                  : 0);
              return item.transaction_type === "Returns" ? (
                <tr>
                  <td name="No">{index++}</td>
                  <td name="Date">{item.transaction_date}</td>
                  <td name="Amount">
                    {currencySymbol}{" "}
                    {item.transaction_amount.toLocaleString("en-US")}
                  </td>
                  {(account_type == "Equity" && (
                    <>
                      <td>${item.customer_share.toLocaleString("en-US")}</td>
                      <td>${item.company_share.toLocaleString("en-US")}</td>
                      <td>
                        {(
                          (item.transaction_amount * 100) /
                          item.balance
                        ).toFixed(2)}{" "}
                        %
                        {/* {console.log(
                          "🚀 ~ file: Returns.js ~ line 380 ~ {tempTD.map ~ item",
                          item.transaction_date
                        )} */}
                        {/* {tempTD.map((i) => {
                          if (i.transaction_type == "Deposit") {
                            if (
                              moment(
                                new Date(item.transaction_date)
                              ).isSameOrBefore(
                                new Date(i.transaction_date),
                                "month"
                              )
                            ) {
                              console.log(
                                "🚀 ~ file: Returns.js ~ line 392 ~ {tempTD.map ~ i.transaction_amount",
                                i.transaction_amount
                              );
                              // totalDeposit =
                              //   totalDeposit + i.transaction_amount;
                            }
                          }
                        })} */}
                        {
                          // (payoutPercent =
                          //   (item.transaction_amount * 100) / totalDeposit)
                        }
                        {/* {console.log(
                          "🚀 ~ file: Returns.js ~ line 389 ~ {tempTD.map ~ payoutPercent",
                          payoutPercent
                        )} */}
                      </td>
                    </>
                  )) || <td name="Currency">{currency}</td>}
                  {isEdit && (
                    <td>
                      <Button
                        className="badge bg-danger"
                        style={{ padding: "0.5rem" }}
                        onClick={() => handleDeleteShow(item)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </Button>
                    </td>
                  )}
                </tr>
              ) : (
                ""
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-responsive">
        <table
          id="datatable"
          className="table data-table table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>Total Returns Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {currencySymbol} {totalReturn.toLocaleString("en-US")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Returns;
