import React from "react";
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";
import { useHistory } from "react-router-dom";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";

import { useDispatch } from "react-redux";
import { login } from "../../../actions/auth/auth";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const SignIn = (props) => {
  const dispatch = useDispatch();
  //   const { Formik } = "formik";
  let history = useHistory();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });
  return (
    <>
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-3">
                <Card.Body>
                  <div className="auth-logo">
                    <img
                      src={logo}
                      className={`img-fluid  rounded-normal  ${
                        !props.darkMode ? "d-none" : ""
                      }`}
                      alt="logo"
                    />
                    <img
                      src={darklogo}
                      className={`img-fluid  rounded-normal  ${
                        props.darkMode ? "d-none" : ""
                      }`}
                      alt="logo"
                    />
                  </div>
                  <h3 className="mb-3 font-weight-bold text-center">Sign In</h3>
                  <p className="text-center text-secondary mb-4">
                    Log in to your account to continue
                  </p>
                  <Formik
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      dispatch(login(values));
                    }}
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col lg="12">
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Email
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter Email"
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg="12" className="mt-2">
                            <Form.Group>
                              <div className="d-flex justify-content-between align-items-center">
                                <Form.Label className="text-secondary">
                                  Password
                                </Form.Label>
                                {/* <Form.Label>
                                  <Link to="/auth/recoverpw">
                                    Forgot Password?
                                  </Link>
                                </Form.Label> */}
                              </div>
                              <Form.Control
                                type="password"
                                name="password"
                                onChange={handleChange}
                                placeholder="Enter Password"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          type="submit"
                          variant="btn btn-primary btn-block mt-2"
                        >
                          Log In
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(SignIn);
