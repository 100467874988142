import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useParams, useLocation } from "react-router-dom";
// img
import avatar from "../../../assets/images/avatar-3.png";
import { useDispatch, useSelector } from "react-redux";
import {
  accountAdd,
  getAccount,
  accountDelete,
  accountStatusUpdate,
} from "../../../actions/accountAction/accountAction";
import DocUpload from "../../../components/DocUpload";
import {
  documentGet,
  resetPassword,
} from "../../../actions/userAction/userAction";

const CustomerDetails = (props) => {
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [account, setAccount] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const handleDeleteSubmit = () => {
    dispatch(accountDelete(account.id));
    setShowDelete(false);
    dispatch(getAccount(customer.id));
  };

  const handleStatusChange = () => {
    dispatch(
      accountStatusUpdate({
        account_status: !account.account_status,
        account_id: account.id,
      })
    );
    setShowStatus(false);
    dispatch(getAccount(customer.id));
  };

  const dispatch = useDispatch();

  const location = useLocation();
  const [customer, setCustomer] = useState(location.state.data);

  const accountDetails = useSelector((state) => state.accountReducer.accounts);
  const documentsDetails = useSelector(
    (state) => state.documentReducer.document[0]
  );
  console.log(
    "🚀 ~ file: CustomerDetails.js:66 ~ CustomerDetails ~ documentsDetails",
    documentsDetails
  );

  const [passport, setPassport] = useState(documentsDetails?.passport);
  const [visa, setVisa] = useState(documentsDetails?.visa);
  const [eid, setEid] = useState(documentsDetails?.eid);
  const [kyc, setKyc] = useState(documentsDetails?.kyc);
  const [agreement, setAgreement] = useState(documentsDetails?.agreement);

  let sortedAccountDetails = accountDetails;
  sortedAccountDetails.sort((a, b) => {
    return new Date(a.commencement_date) - new Date(b.commencement_date);
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const account_validation = Yup.object().shape({
    commencement_date: Yup.date().required("Required"),
    termination_date: Yup.date().min(
      Yup.ref("commencement_date"),
      "Termination date cannot be before commencement date"
    ),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowDoc = () => setShowDoc(false);

  useEffect(() => {
    dispatch(getAccount(customer.id));
    dispatch(documentGet(customer.id));
  }, []);

  return (
    <>
      {/* Delete Account Modal */}
      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the account?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDelete(false)}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleDeleteSubmit()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Account Modal */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title as="h5">Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => {
              values.id = customer.id;
              values.account_status = true;
              if (values.account_type == "Debt") {
                delete values.account_number;
              }
              if (values.account_type == "Equity") {
                values.currency = "USD";
                values.payout = 0;
                values.account_balance = 0;
              }
              dispatch(accountAdd(values));
              setShow(false);
            }}
            validationSchema={account_validation}
            initialValues={{
              account_type: "",
              account_number: "",
              account_balance: "",
              payout: "",
              currency: "",
              commencement_date: "",
              termination_date: "",
              account_status: "",
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit} id="add_account">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Account Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="account_type"
                        onChange={handleChange}
                      >
                        <option>Select account type</option>
                        <option value="Debt">Debt</option>
                        <option value="Equity">Equity</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    {values.account_type == "Debt" ? (
                      <Form.Group controlId="accountNumber">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Automatically Generated"
                          disabled
                          readOnly
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="accountNumber">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="account_number"
                          placeholder="Enter Account Number"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {values.account_type == "Equity" ? (
                      <Form.Group controlId="payoutRate">
                        <Form.Label>Payout Rate</Form.Label>
                        <Form.Control type="text" disabled readOnly />
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="payoutRate">
                        <Form.Label>Payout Rate</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Payout Rate"
                          name="payout"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                  <Col>
                    {values.account_type === "Equity" ? (
                      <Form.Group controlId="investedAmount">
                        <Form.Label>Currency</Form.Label>
                        <Form.Control
                          type="text"
                          name="currency"
                          disabled
                          readOnly
                          placeholder="USD ($)"
                        ></Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="investedAmount">
                        <Form.Label>Currency</Form.Label>
                        <Form.Control
                          as="select"
                          name="currency"
                          onChange={handleChange}
                        >
                          <option>Select account currency</option>
                          <option value="USD">USD ($)</option>
                          <option value="AED">AED (د.إ)</option>
                          <option value="INR">INR (₹)</option>
                          <option value="EUR">EUR (€)</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {values.account_type === "Equity" ? (
                      <Form.Group controlId="investedAmount">
                        <Form.Label>Investment Amount</Form.Label>
                        <Form.Control
                          type="text"
                          name="account_balance"
                          disabled
                          readOnly
                          placeholder="N / A"
                        ></Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="investedAmount">
                        <Form.Label>Investment Amount</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Investment Amount"
                          name="account_balance"
                          onChange={handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Commencement Date</Form.Label>
                      <Form.Control
                        type="date"
                        required
                        name="commencement_date"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {values.account_type == "Debt" ? (
                    <Col>
                      <Form.Group>
                        <Form.Label>Termination Date</Form.Label>
                        <Form.Control
                          type="date"
                          required
                          name="termination_date"
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.min}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" form="add_account">
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Password Change Modal */}
      <Modal
        show={showPass}
        onHide={() => setShowPass(false)}
        size="l"
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={(values) => {
              values.user_id = customer.id;
              delete values.confirm_password;
              dispatch(resetPassword(values));
              setShowPass(false);
            }}
            validationSchema={validationSchema}
            initialValues={{
              password: "",
              confirm_password: "",
            }}
          >
            {({ handleSubmit, handleChange, touched, errors, values }) => (
              <Form noValidate onSubmit={handleSubmit} id="change_password">
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="text"
                        name="password"
                        placeholder="New Password"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        isInvalid={!!errors.confirm_password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirm_password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => setShowPass(false)}>
            Close
          </Button>
          <Button variant="success" type="submit" form="change_password">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Account Status Change Modal */}
      <Modal show={showStatus} onHide={() => setShowStatus(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to change the status of account?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStatus(false)}>
            No
          </Button>
          <Button variant="danger" onClick={() => handleStatusChange()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Documents Upload Modal */}
      <Modal show={showDoc} onHide={handleShowDoc} size="xl">
        <Modal.Header closeButton>
          <Modal.Title as="h5">Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table data-table mb-0">
            <thead className="table-color-heading">
              <tr className="text-muted">
                <th scope="col">Document</th>
                <th scope="col">Upload</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Passport</td>
                <td>
                  {passport != null ? (
                    <>
                      <a
                        href={passport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="outline mt-2 btn-with-icon">
                          <i className="fas fa-file-pdf"></i>
                          {passport.split(".com/")[1]}
                        </Button>
                      </a>
                      <Button
                        variant="secondary"
                        onClick={() => setPassport("")}
                      >
                        Edit
                      </Button>
                    </>
                  ) : (
                    <DocUpload
                      id="passport"
                      name="passport"
                      user_id={customer.id}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>Aggrement</td>
                <td>
                  {agreement != null ? (
                    <>
                      <a
                        href={agreement}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="outline mt-2 btn-with-icon">
                          <i className="fas fa-file-pdf"></i>
                          {agreement.split(".com/")[1]}
                        </Button>
                      </a>
                      <Button
                        variant="secondary"
                        onClick={() => setAgreement("")}
                      >
                        Edit
                      </Button>
                    </>
                  ) : (
                    <DocUpload
                      id="agreement"
                      name="agreement"
                      user_id={customer.id}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>VISA</td>
                <td>
                  {visa != null ? (
                    <>
                      <a href={visa} target="_blank" rel="noopener noreferrer">
                        <Button variant="outline mt-2 btn-with-icon">
                          <i className="fas fa-file-pdf"></i>
                          {visa.split(".com/")[1]}
                        </Button>
                      </a>
                      <Button variant="secondary" onClick={() => setVisa("")}>
                        Edit
                      </Button>
                    </>
                  ) : (
                    <DocUpload id="visa" name="visa" user_id={customer.id} />
                  )}
                </td>
              </tr>
              <tr>
                <td>EID</td>
                <td>
                  {eid != null ? (
                    <>
                      <a href={eid} target="_blank" rel="noopener noreferrer">
                        <Button variant="outline mt-2 btn-with-icon">
                          <i className="fas fa-file-pdf"></i>
                          {eid.split(".com/")[1]}
                        </Button>
                      </a>
                      <Button variant="secondary" onClick={() => setEid("")}>
                        Edit
                      </Button>
                    </>
                  ) : (
                    <DocUpload id="eid" name="eid" user_id={customer.id} />
                  )}
                </td>
              </tr>
              <tr>
                <td>KYC</td>
                <td>
                  {kyc != null ? (
                    <>
                      <a href={kyc} target="_blank" rel="noopener noreferrer">
                        <Button variant="outline mt-2 btn-with-icon">
                          <i className="fas fa-file-pdf"></i>
                          {kyc.split(".com/")[1]}
                        </Button>
                      </a>
                      <Button variant="secondary" onClick={() => setKyc("")}>
                        Edit
                      </Button>
                    </>
                  ) : (
                    <DocUpload id="kyc" name="kyc" user_id={customer.id} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowDoc}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>

      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/customer">Clients</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Clients View
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/customer"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold d-flex align-items-center">
              Client View
            </h4>
          </Col>
        </Row>

        <Row>
          <Col lg="8">
            <Card>
              <ListGroup as="ul" className="list-group-flush">
                <ListGroup.Item as="li">
                  <div>
                    <ListGroup as="ul" className="list-style-1 mb-0">
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="avatar">
                          <img
                            className="avatar avatar-img avatar-60 rounded-circle"
                            src={customer.image || avatar}
                            alt="01.jpg"
                          />
                        </div>
                        <div className="list-style-detail ml-4 mr-2">
                          <h5 className="font-weight-bold">
                            {customer.firstname} {customer.lastname}
                          </h5>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                  {userData.role == "Admin" && (
                    <Row className="mt-3">
                      <Col xs="3" className="text-center">
                        <Link
                          className="btn btn-block btn-sm btn-secondary"
                          to={{
                            pathname: `/user-add/edit/${customer.id}`,
                            state: { data: customer },
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                          <span className="">Edit Profile</span>
                        </Link>
                      </Col>
                      <Col xs="3" className="text-center">
                        <Button
                          className="btn btn-block btn-sm btn-warning"
                          onClick={() => setShowPass(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                          <span className="">Edit Password</span>
                        </Button>
                      </Col>
                      <Col xs="3" className="text-center">
                        <Button
                          className="btn btn-block btn-sm btn-success"
                          onClick={() => {
                            setShowDoc(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                          <span className="">Upload Documents</span>
                        </Button>
                      </Col>
                    </Row>
                  )}
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <table className="table table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Email ID</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.email}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Birthday</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.dob}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Phone</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.phone_no}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Country</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.country}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">State/Region</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.state}</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0">
                          <p className="mb-0 text-muted">Address</p>
                        </td>
                        <td>
                          <p className="mb-0 ">{customer.address}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body className="p-0">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h5>Account List</h5>
                  {userData.role == "Admin" && (
                    <span className="table-add float-right mr-2">
                      <button
                        onClick={() => handleShow()}
                        className="btn btn-sm bg-primary"
                      >
                        <i className="ri-add-fill">
                          <span className="pl-1">Add New</span>
                        </i>
                      </button>
                    </span>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table data-table mb-0">
                    <thead className="table-color-heading">
                      <tr className="">
                        <th scope="col">S.No</th>
                        <th scope="col">A/c Number</th>
                        <th scope="col">A/c Type</th>
                        <th scope="col">Payout(%)</th>
                        <th scope="col">A/c Balance</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-right">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedAccountDetails.map((item, index) => {
                        let path =
                          item.account_type == "Debt"
                            ? `/account-history-debt/${item.id}`
                            : `/account-history-equity/${item.id}`;
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.account_number}</td>
                            <td>{item.account_type}</td>
                            <td>
                              {item.account_type == "Debt"
                                ? item.payout
                                : "N/A"}
                            </td>
                            <td>
                              {item.currency}{" "}
                              {item.account_balance.toLocaleString("en-US")}
                            </td>
                            <td>{item.commencement_date}</td>
                            <td>
                              <div className="custom-control custom-switch custom-control-inline">
                                <Form.Check.Input
                                  bsPrefix="custom-control-input"
                                  id={`statusToggle${item.id}`}
                                  checked={item.account_status}
                                  onClick={() => {
                                    setAccount(item);
                                    setShowStatus(true);
                                  }}
                                />
                                <Form.Check.Label
                                  bsPrefix="custom-control-label"
                                  htmlFor={`statusToggle${item.id}`}
                                >
                                  {item.account_status
                                    ? "Active"
                                    : "Terminated"}
                                </Form.Check.Label>
                              </div>
                            </td>
                            <td>
                              <div className="justify-content-end align-items-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>View</Tooltip>}
                                >
                                  <Link
                                    className=""
                                    to={{
                                      pathname: path,
                                      state: { account: item },
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="text-secondary mx-2"
                                      width="20"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                      />
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                      />
                                    </svg>
                                  </Link>
                                </OverlayTrigger>
                                {userData.role == "Admin" && (
                                  <Button
                                    className="badge bg-danger"
                                    style={{ padding: "0.5rem" }}
                                    onClick={() => {
                                      setAccount(item);
                                      setShowDelete(true);
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </Button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CustomerDetails;
