module.exports = {
  USER_LOADED: "USER_LOADED",
  AUTH_ERROR: "AUTH_ERROR",
  SUCCESS_MESSAGE: "SUCCESS_MESSAGE",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  WARNING_MESSAGE: "WARNING_MESSAGE",
  INFO_MESSAGE: "INFO_MESSAGE",
  GET_USERS: "GET_USERS",
  GET_ACCOUNTS: "GET_ACCOUNTS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_FEEDBACK: "GET_FEEDBACK",
  LOADING_TOGGLE: "LOADING_TOGGLE",
  GET_EMP_USERS: "GET_EMP_USERS",
  GET_USER_TYPE: "GET_USER_TYPE",
  GET_DOCUMENTS: "GET_DOCUMENTS",
};
