//router
import LayoutsRoute from "./router/layouts-route";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
//scss files
import "./assets/scss/backend.scss";
import "./assets/css/custom.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const auth = useSelector((state) => state.authReducer);
  const message = useSelector((state) => state.toasterReducer);
  // console.log(auth);
  useEffect(() => {
    switch (message.type) {
      case "success":
        toast.success(message.message, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
        break;
      case "error":
        toast.error(message.message, {
          position: toast.POSITION.TOP_RIGHT,
          theme: "colored",
        });
        break;
      case "warning":
        toast.warning(message.message, {
          position: toast.POSITION.TOP_LEFT,
          theme: "colored",
        });
        break;
      default:
        toast.info(message.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
        break;
    }
  }, [message]);
  return (
    <div className="App">
      <LayoutsRoute auth={auth} />
      <ToastContainer limit={2} autoClose={1000} />
    </div>
  );
}

export default App;
