import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

import moment from "moment";

import Card from "../../../components/Card";

//ApexCharts
import Chart from "react-apexcharts";
import { color } from "highcharts";

const AccountAnalysis = (props) => {
  const months = [],
    amounts = [],
    barColors = [];

  props.transactionDetails.map((item) => {
    // if (item.transaction_type == "Returns") {
    months.push(moment(item.transaction_date).format("MMM"));
    amounts.push(item.transaction_amount);
    item.transaction_type == "Withdrawal"
      ? barColors.push("#ff4b4b")
      : barColors.push("#4788ff");
    // }
  });
  console.log(
    "🚀 ~ file: AccountAnalysis.js ~ line 25 ~ props.transactionDetails.map ~ barColors",
    barColors
  );

  const chart8 = {
    options: {
      chart: {
        id: "apex-bar",
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: 0,
        },
      },
      dataLabels: {
        enabled: !1,
      },
      colors: barColors,
      xaxis: {
        categories: months,
      },
    },
    series: [
      {
        data: amounts,
      },
    ],
  };
  return (
    <>
      <Card>
        {/* <Card.Header>
          <Card.Header.Title>
            <h4 className="card-title">Bar Chart</h4>
          </Card.Header.Title>
        </Card.Header> */}
        <Card.Body>
          <Chart
            options={chart8.options}
            series={chart8.series}
            type="bar"
            height="350"
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AccountAnalysis;
