import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { connect } from "react-redux";
import { getDarkMode } from "../../../../store/mode";

import { useSelector } from "react-redux";

//img
import logo from "../../../../../src/assets/images/logo.png";
import { Audio } from "react-loader-spinner";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const minisidbar = () => {
  document.body.classList.toggle("sidebar-main");
};

const SidebarStyle = (props) => {
  //location
  let location = useLocation();

  const userAccountData = useSelector((state) => state.accountReducer.accounts);

  const userData = props.user;
  const equityAccounts = [],
    debtAccounts = [];
  userAccountData.map((item) => {
    if (item.account_type == "Debt") {
      debtAccounts.push(item);
    } else if (item.account_type == "Equity") {
      equityAccounts.push(item);
    }
  });
  let sortedDebtAccounts = debtAccounts,
    sortedEquityAccounts = equityAccounts;
  sortedDebtAccounts.sort((a, b) => {
    return new Date(a.commencement_date) - new Date(b.commencement_date);
  });
  sortedEquityAccounts.sort((a, b) => {
    return new Date(a.commencement_date) - new Date(b.commencement_date);
  });

  const urlParams = new URLSearchParams(window.location.search);
  const sidebar = urlParams.get("sidebar");
  var variant = "";
  if (sidebar !== null) {
    variant = "";
    switch (sidebar) {
      case "0":
        variant = "sidebar-dark";
        break;
      case "1":
        variant = "sidebar-light";
        break;
      default:
        variant = "";
        break;
    }
  }

  // Collapse state
  const [activeMenu, setActiveMenu] = useState(false);
  const [activesubMenu, setSubmenu] = useState(false);
  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));
  });
  return userData ? (
    <>
      <div className={`iq-sidebar sidebar-default ${variant}`}>
        <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
          <Link to="/" className="header-logo">
            <img
              src={logo}
              className={`img-fluid rounded-normal light-logo ${
                props.darkMode ? "d-none" : ""
              }`}
              alt="logo"
            />
            <span>Hedge&Sachs</span>
          </Link>
          <div className="side-menu-bt-sidebar-1">
            <svg
              onClick={minisidbar}
              xmlns="http://www.w3.org/2000/svg"
              className="text-light wrapper-menu"
              width="30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
          <nav className="iq-sidebar-menu">
            <Accordion
              as="ul"
              id="iq-sidebar-toggle"
              className="side-menu"
              onSelect={(e) => setActiveMenu(e)}
            >
              <li
                className={`${
                  location.pathname === "/" ? "active" : ""
                }  sidebar-layout`}
              >
                <Link to="/" className="svg-icon">
                  <i className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                  </i>
                  <span className="ml-2">Dashboard</span>
                  <p className="mb-0 w-10 badge badge-pill badge-primary">6</p>
                </Link>
              </li>
              <li className="px-3 pt-3 pb-2 ">
                <span className="text-uppercase small font-weight-bold">
                  Application
                </span>
              </li>
              {userData &&
                (userData.role == "Admin" || userData.role == "Employee") && (
                  <>
                    <li
                      className={`${
                        location.pathname === "/customer" ? "active" : ""
                      }  sidebar-layout`}
                    >
                      <Link to="/customer" className="svg-icon ">
                        <i className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                          </svg>
                        </i>
                        <span className="ml-2">Clients</span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/employee" ? "active" : ""
                      }  sidebar-layout`}
                    >
                      <Link to="/employee" className="svg-icon">
                        <i className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                            />
                          </svg>
                        </i>
                        <span className="ml-2">Employee</span>
                      </Link>
                    </li>
                    <li
                      className={`${
                        location.pathname === "/todo" ? "active" : ""
                      }  sidebar-layout`}
                    >
                      <Link to="/clientfeedback" className="svg-icon">
                        <i className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                            />
                          </svg>
                        </i>
                        <span className="ml-2">Feedback</span>
                      </Link>
                    </li>
                  </>
                )}
              {/* Client Sidebar */}
              {userData && userData.role == "Customer" && (
                <>
                  {/* My Profile */}
                  <li
                    className={`${
                      location.pathname === "/user-profile" ? "active" : ""
                    }  sidebar-layout`}
                  >
                    <Link
                      to={{
                        pathname: `/user-profile/${userData.id}`,
                        state: { data: userData },
                      }}
                      className="svg-icon "
                    >
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                          />
                        </svg>
                      </i>
                      <span className="ml-2">My Profile</span>
                    </Link>
                  </li>
                  {/* Accounts */}
                  <li
                    className={`${
                      activeMenu === "3" ? "active" : ""
                    } sidebar-layout`}
                  >
                    <Accordion.Toggle
                      as={Button}
                      eventKey="3"
                      href="#"
                      variant="collapsed svg-icon"
                      data-toggle="collapse"
                      aria-expanded={activeMenu === "3" ? "true" : "false"}
                    >
                      <i>
                        <svg
                          className="svg-icon"
                          id="iq-form-1"
                          width="18"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                            strokeDasharray="74, 94"
                            strokeDashoffset="0"
                          ></path>
                        </svg>
                      </i>
                      <span className="ml-2">Accounts</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon iq-arrow-right arrow-active"
                        width="15"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </Accordion.Toggle>
                    <Accordion.Collapse className="submenu" eventKey="3">
                      <Accordion
                        as="ul"
                        id=""
                        className="submenu "
                        data-parent="#iq-sidebar-toggle"
                        onSelect={(e) => setSubmenu(e)}
                      >
                        {debtAccounts.length && (
                          <li
                            className={`${
                              location.pathname === "/form-layout" ||
                              location.pathname === "/form-input-group" ||
                              location.pathname === "/form-validation" ||
                              location.pathname === "/form-switch" ||
                              location.pathname === "/form-chechbox" ||
                              location.pathname === "/form-radio" ||
                              location.pathname === "/form-textarea"
                                ? "active"
                                : ""
                            }  sidebar-layout`}
                          >
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              href="#"
                              variant="collapsed svg-icon"
                              data-toggle="collapse"
                              aria-expanded={
                                activesubMenu === "0" ? "true" : "false"
                              }
                            >
                              <i className="">
                                <svg
                                  className="svg-icon"
                                  width="18"
                                  id="iq-form-1-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    strokeDasharray="61, 81"
                                    strokeDashoffset="0"
                                  ></path>
                                </svg>
                              </i>
                              <span className="">Debt Account</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-icon iq-arrow-right arrow-active"
                                width="15"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5l7 7-7 7"
                                ></path>
                              </svg>
                            </Accordion.Toggle>
                            <Accordion.Collapse
                              className="submenu"
                              eventKey="0"
                            >
                              <ul
                                id="form1"
                                className="submenu "
                                data-parent="#"
                              >
                                {sortedDebtAccounts.map((item) => {
                                  let path = `/account-history-debt/${item.id}`;
                                  return (
                                    <li className="sidebar-layout">
                                      <Link
                                        to={{
                                          pathname: path,
                                          state: { account: item },
                                        }}
                                        className="svg-icon"
                                      >
                                        <i className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                                            />
                                          </svg>
                                        </i>
                                        <span className="">
                                          {item.account_number}
                                        </span>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Accordion.Collapse>
                          </li>
                        )}
                        {!!equityAccounts.length && (
                          <li className="sidebar-layout">
                            <Accordion.Toggle
                              as={Button}
                              eventKey="1"
                              href="#"
                              variant="collapsed svg-icon"
                              data-toggle="collapse"
                              aria-expanded={
                                activesubMenu === "1" ? "true" : "false"
                              }
                            >
                              <i className="">
                                <svg
                                  className="svg-icon"
                                  width="18"
                                  id="iq-user-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  />
                                </svg>
                              </i>
                              <span className="">Equity Account</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-icon iq-arrow-right arrow-active"
                                width="15"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M9 5l7 7-7 7"
                                ></path>
                              </svg>
                            </Accordion.Toggle>
                            <Accordion.Collapse
                              className="submenu"
                              eventKey="1"
                            >
                              <ul
                                id="form2"
                                className="submenu"
                                data-parent="#"
                              >
                                {sortedEquityAccounts.map((item) => {
                                  let path = `/account-history-equity/${item.id}`;
                                  return (
                                    <li
                                      className={`${
                                        location.pathname === "/form-layout"
                                          ? "active"
                                          : ""
                                      }  sidebar-layout`}
                                    >
                                      <Link
                                        to={{
                                          pathname: path,
                                          state: { account: item },
                                        }}
                                        className="svg-icon"
                                      >
                                        <i className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                                            />
                                          </svg>
                                        </i>
                                        <span className="">
                                          {item.account_number}
                                        </span>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </Accordion.Collapse>
                          </li>
                        )}
                      </Accordion>
                    </Accordion.Collapse>
                  </li>
                  {/* <li
                    className={`${
                      location.pathname === "/pages-invoice" ? "active" : ""
                    }  sidebar-layout`}
                  >
                    <Link to="/pages-invoice" className="svg-icon">
                      <i className="">
                        <svg
                          className="icon line"
                          width="18"
                          id="receipt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            d="M17,16V3L13,5,10,3,7,5,3,3V17.83A3.13,3.13,0,0,0,5.84,21,3,3,0,0,0,9,18V17a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1v1a3,3,0,0,1-3,3H6"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <line
                            x1="8"
                            y1="10"
                            x2="12"
                            y2="10"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></line>
                        </svg>
                      </i>
                      <span className="ml-2">SOA</span>
                    </Link>
                  </li> */}
                  {/* Feedback */}
                  <li
                    className={`${
                      location.pathname === "/todo" ? "active" : ""
                    }  sidebar-layout`}
                  >
                    <Link to="/clientfeedback" className="svg-icon">
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                          />
                        </svg>
                      </i>
                      <span className="ml-2">Feedback</span>
                    </Link>
                  </li>
                </>
              )}
            </Accordion>
          </nav>
        </div>
      </div>
    </>
  ) : (
    <Audio
      height="80"
      width="80"
      radius="9"
      color="green"
      ariaLabel="three-dots-loading"
      wrapperStyle
      wrapperClass
    />
  );
};

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle);
