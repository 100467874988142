import Card from "../components/Card";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Tab,
  Nav,
} from "react-bootstrap";

const AccountContent = (props) => {
  return (
    <div
      className={`chat-content animate__animated animate__fadeIn ${
        props.active ? "active" : ""
      }`}
      data-toggle-extra="tab-content"
      id={`user-content-${props.id}`}
    >
      <Card>
        <Card.Body className="p-0">
          <Tab.Container defaultActiveKey="first">
            <Tab.Content>
              <Tab.Pane eventKey="first" className=" fade show  ">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h5>Transactions List ({props.number})</h5>
                  <Button variant="btn btn-secondary btn-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-1"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    Export
                  </Button>
                </div>
                <div className="table-responsive">
                  <table className="table data-table mb-0">
                    <thead className="table-color-heading">
                      <tr className="text-muted">
                        <th scope="col">Date</th>
                        <th scope="col">Details </th>
                        <th scope="col">Deposit</th>
                        <th scope="col">Withdrawal</th>
                        <th scope="col">Profit(in %)</th>
                        <th scope="col">Commision</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12 Jan 2020</td>
                        <td>
                          <p className="mb-0 text-success">Credit</p>
                        </td>
                        <td>$10,000</td>
                        <td>-</td>
                        {/* <td>$10,000</td> */}
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>12 Jan 2020</td>
                        <td>
                          <p className="mb-0 text-danger">Debit</p>
                        </td>
                        <td>-</td>
                        <td>$231</td>
                        {/* <td>$9,769</td> */}
                        <td className="text-success">5.08 %</td>
                      </tr>
                      <tr>
                        <td>12 Jan 2020</td>
                        <td>
                          <p className="mb-0 text-success">Credit</p>
                        </td>
                        <td>$500</td>
                        <td>-</td>
                        {/* <td>$10,269</td> */}
                        <td className="text-success">5.08 %</td>
                      </tr>
                      <tr className="table-info">
                        <th scope="row">Totals</th>
                        <td></td>
                        <th>$123,21</th>
                        <th>$156,43</th>
                        <th>Balance: $500,000</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </div>
  );
};
export default AccountContent;
