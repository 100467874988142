let BASE_URL = "";
let URL = "";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  BASE_URL = "https://main.hedgeandsachs.com/api/";
  URL = "https://main.hedgeandsachs.com/api/";
  //   BASE_URL = "http://localhost/api/";
  //   URL = "http://localhost/api/";
} else {
  BASE_URL = "https://main.hedgeandsachs.com/api/";
  URL = "https://main.hedgeandsachs.com/api/";
  //   BASE_URL = "http://localhost:3000/api/";
  //   URL = "http://localhost:3000/api/";
}
const config = {
  BASE_URL,
  URL,
};

export default config;
