import React, { useEffect } from "react";
import { Link, Redirect, Route } from "react-router-dom";
import * as localStorage from "../common/localStorage";

function PrivateRoute({ component: Component, ...rest }) {
  const token = localStorage.get("token") ? localStorage.get("token") : null;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/sign-in" />;
        }
      }}
    />
  );
  //   token ? children : <Link to="/auth/sign-in"
  //    />;
}
export default PrivateRoute;
