import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const accountAdd = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}accounts`, payload)
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
        dispatch(getAccount(payload.id));
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const accountStatusUpdate = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}accounts/acc_status`, payload)
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const getAccount = (payload) => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}accounts/${payload}`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.GET_ACCOUNTS,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const getCurrentUserAccount = () => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}accounts`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.GET_ACCOUNTS,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const accountDelete = (account_id) => (dispatch) => {
  ajaxCall
    .remove(`${config.BASE_URL}accounts`, { id: account_id })
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};
