import React, { useCallback, useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

//leaflet
import Leaflet from "../../../components/leaflet";
import moment from "moment";

//datepicker
import Datepickers from "../../../components/Datepicker";
import { useSelector, useDispatch } from "react-redux";

//circularpro
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Container, Row, Col, Table, Dropdown } from "react-bootstrap";
import Card from "../../../components/Card";
import { Audio } from "react-loader-spinner";
import { getEmployeeClient } from "../../../actions/userAction/employeeAction";

const EmployeeDashboard = (props) => {
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userdata"));
  const clientList = useSelector((state) => state.employeeReducer.users);
  console.log(
    "🚀 ~ file: EmployeeDashboard.js:26 ~ EmployeeDashboard ~ clientList",
    clientList
  );

  useEffect(() => {
    dispatch(getEmployeeClient(userData.id));
  }, []);

  return userData ? (
    <Container fluid>
      <Row>
        <Col md="12" className="mb-4 mt-1">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h4 className="font-weight-bold">Overview</h4>
            <div className="form-group mb-0 vanila-daterangepicker d-flex flex-row">
              <div className="date-icon-set">
                <Datepickers
                  className="vanila-datepicker"
                  names="start"
                  placeholder="From Date"
                />
                <span className="search-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </div>
              <span className="flex-grow-0">
                <span className="btn">To</span>
              </span>
              <div className="date-icon-set">
                <Datepickers names="end" placeholder="To Date" />
                <span className="search-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    width="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg="8" md="12">
          <Row>
            <Col md="4">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Total Clients</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">
                          {clientList.length}
                        </h5>
                        {/* <p className="mb-0 ml-3 text-danger font-weight-bold">
                          -9.98%
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Total Investement</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">Coming Soon</h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col md="4">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Total Profit</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">$12,789</h5>
                        
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            <Col md="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Top Clients</h4>
                  </div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-color-heading">
                        <tr className="text-secondary">
                          <th scope="col">Date</th>
                          <th scope="col">Client</th>
                          {/* <th scope="col">Status</th> */}
                          <th scope="col" className="text-right">
                            Total Investment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientList &&
                          clientList.slice(0, 5).map((item) => (
                            <tr className="white-space-no-wrap">
                              <td>
                                {moment(item.created_at).format("MMMM Do")}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    {item.gender == "FEMALE"
                                      ? `Ms/Mrs ${item.firstname} ${item.lastname}`
                                      : `Mr ${item.firstname} ${item.lastname}`}
                                  </div>
                                </div>
                              </td>
                              <td className="text-right"></td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end align-items-center border-top-table p-3">
                      <button className="btn btn-secondary btn-sm">
                        See All
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  ) : (
    <Audio
      height="80"
      width="80"
      radius="9"
      color="green"
      ariaLabel="three-dots-loading"
      wrapperStyle
      wrapperClass
    />
  );
};

export default EmployeeDashboard;
