import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

import moment from "moment";

//datepicker
import Datepickers from "../../../components/Datepicker";
import { color } from "highcharts";

const Soa = (props) => {
  const [tempTD, setTempTD] = useState([...props.transactionDetails]);

  useEffect(() => {
    setTempTD(props.transactionDetails);
  }, [props.transactionDetails]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateFilter, setDateFilter] = useState(false);

  const handleFilter = () => {
    let temp = [...props.transactionDetails];
    if (!dateFilter) {
      temp = props.transactionDetails.filter((item) => {
        return (
          fromDate &&
          moment(new Date(fromDate)) <=
            moment(new Date(item.transaction_date)) &&
          toDate &&
          moment(new Date(toDate)) >= moment(new Date(item.transaction_date))
        );
      });
    }
    setTempTD(temp);
  };

  const filterDate = () => {
    if (fromDate || toDate) {
      setDateFilter(true);
    }
  };
  const {
    accountDetails: { currency, account_balance, account_type },
    accountDetails,
  } = props;
  let currencySymbol =
    currency == "USD"
      ? "$"
      : currency == "AED"
      ? "AED"
      : currency == "INR"
      ? "₹"
      : "€";
  let index = 0;
  let a = 0;
  let totalReturn = account_balance;
  let capitalGainArr = [];
  tempTD
    .slice()
    .reverse()
    .map((item) => {
      if (item.transaction_type == "Returns") {
        totalReturn += item.transaction_amount;
        capitalGainArr.push(totalReturn);
      }
    });
  capitalGainArr.reverse();

  return (
    <>
      <div id="table" className="table-editable">
        <div className="table-add float-right mb-3 mr-2">
          <h6>Commencement Date : {accountDetails.commencement_date}</h6>
        </div>
        <div
          className="form-group vanila-daterangepicker d-flex flex-row"
          id="date_filter"
          style={{ marginTop: "3%", marginBottom: "3%" }}
        >
          <div className="date-icon-set">
            <Datepickers
              className="vanila-datepicker"
              names="start"
              placeholder="From Date"
              value={fromDate}
              onBlur={(e) => setFromDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span className="flex-grow-0">
            <span className="btn">To</span>
          </span>
          <div className="date-icon-set">
            <Datepickers
              names="end"
              placeholder="To Date"
              value={toDate}
              onBlur={(e) => setToDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span
            className="table-add float-right mb-3 mr-2"
            style={{
              marginLeft: "30px",
            }}
          >
            {!dateFilter ? (
              <button
                onClick={() => {
                  filterDate();
                  handleFilter();
                }}
                className="btn btn-sm bg-warning"
              >
                <span className="pl-1">Filter</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  setDateFilter(false);
                  setToDate("");
                  setFromDate("");
                  handleFilter();
                }}
                className="btn btn-sm bg-success"
              >
                <span className="pl-1">Reset</span>
              </button>
            )}
          </span>
        </div>
        <table className="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th>No.</th>
              <th>Month</th>
              <th>Payout (%)</th>
              <th>Payout Amount</th>
              <th>Payout Frequency</th>
              <th>Capital Gain</th>
            </tr>
          </thead>
          <tbody>
            {tempTD.map((item, i) => {
              // if (item.transaction_type == "Returns") {
              return (
                <tr>
                  <td name="No">{++index}</td>
                  <td name="Month">
                    {moment(item.transaction_date).format("MMM (yyyy)")}
                  </td>
                  <td name="Payout">
                    {item.transaction_type == "Withdrawal" ||
                    item.transaction_type == "Deposit"
                      ? "N/A"
                      : (account_type == "Equity" &&
                          (
                            (item.transaction_amount * 100) /
                            item.balance
                          ).toFixed(2)) ||
                        accountDetails.payout + "%"}
                  </td>
                  <td name="PayoutAmount">
                    {currencySymbol}{" "}
                    {item.transaction_amount.toLocaleString("en-US")}
                  </td>
                  <td name="PayoutFrequency">
                    {item.transaction_type == "Withdrawal"
                      ? "WITHDRAWAL"
                      : item.transaction_type == "Deposit"
                      ? "DEPOSIT"
                      : "MONTHLY"}
                  </td>
                  <td name="CapitalGain">
                    {/* {currencySymbol} */}
                    {item.transaction_type == "Withdrawal" ||
                    item.transaction_type == "Deposit"
                      ? "N/A"
                      : currencySymbol +
                        capitalGainArr[a++].toLocaleString("en-US")}
                  </td>
                </tr>
              );
              // } else {
              //   return "";
              // }
            })}
          </tbody>
        </table>
      </div>
      <div className="table-responsive">
        <table
          id="datatable"
          className="table data-table table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>Total Invested Amount</th>
              <th>Total Returns</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {currencySymbol} {account_balance.toLocaleString("en-US")}
              </td>
              <td>
                {currencySymbol}{" "}
                {(totalReturn - account_balance).toLocaleString("en-US")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
          <div>
            <Button variant="btn btn-secondary px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1"
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                />
              </svg>
              Print
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Soa;
