import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { getUsers, userDelete } from "../../../actions/userAction/userAction";
import avatar from "../../../assets/images/avatar-3.png";

import { useDispatch, useSelector } from "react-redux";

const Customer = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const userList = useSelector((state) => state.userReducer.users);
  useEffect(() => {
    dispatch(getUsers("Customer"));
  }, []);

  const [deleteUser, setDeleteUser] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id, firstname, lastname) => {
    setShow(true);
    setDeleteUser({ id, firstname, lastname });
  };
  const handleDelete = () => {
    dispatch(userDelete(deleteUser.id, "Customer"));
    setShow(false);
  };
  const [q, setQ] = useState("");
  const [searchTerm] = useState(["email", "firstname", "lastname"]);

  const handleSearch = (items) => {
    return items.filter((item) => {
      return searchTerm.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  };

  return (
    <>
      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            User : {deleteUser.firstname} {deleteUser.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {deleteUser.firstname}{" "}
          {deleteUser.lastname}{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            No
          </Button>
          <Button
            variant="danger"
            className="warning"
            onClick={() => handleDelete()}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="font-weight-bold">Client</h4>
              </div>
              <div className="create-workform">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="modal-product-search d-flex">
                    <Form className="mr-3 position-relative">
                      <Form.Group className="mb-0">
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="exampleInputText"
                          placeholder="Search Customer"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                        {/* <Link to="#" className="search-link">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </svg>
                        </Link> */}
                      </Form.Group>
                    </Form>
                    {userData.role == "Admin" && (
                      <Link
                        to={{
                          pathname: `/user-add`,
                          state: "",
                        }}
                        className="btn btn-primary position-relative d-flex align-items-center justify-content-between"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-2"
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                        Add Client
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold">Client List</h5>
                      <Button variant="btn btn-secondary btn-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-1"
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                        Export
                      </Button>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="">
                            <th scope="col" className="pr-0 w-01">
                              <div className="d-flex justify-content-start align-items-end mb-1 ">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input m-0"
                                    id="customCheck1"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1"
                                  ></label>
                                </div>
                              </div>
                            </th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Location</th>
                            <th scope="col">Phone</th>
                            <th scope="col" className="text-right">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList &&
                            handleSearch(userList)
                              .filter((item) => item.active)
                              .map((item) => (
                                <tr
                                  key={item.id}
                                  className="white-space-no-wrap"
                                >
                                  <td className="pr-0 ">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input m-0"
                                        id="customCheck"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      ></label>
                                    </div>
                                  </td>
                                  <td className="">
                                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                                      <div className="h-avatar is-medium">
                                        <img
                                          className="avatar rounded-circle"
                                          alt="user-icon"
                                          src={item.image || avatar}
                                        />
                                      </div>
                                      <div className="data-content">
                                        <div>
                                          <span className="font-weight-bold">
                                            {item.gender === "FEMALE"
                                              ? `Ms/Mrs ${item.firstname} ${item.lastname}`
                                              : `Mr ${item.firstname} ${item.lastname}`}
                                          </span>
                                        </div>
                                        <p className="m-0 text-secondary small">
                                          {item.country}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.email}</td>
                                  <td>
                                    {item.country}, {item.city}
                                  </td>
                                  <td>{item.phone_no}</td>
                                  <td>
                                    <div className="d-flex justify-content-end align-items-center">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>View</Tooltip>}
                                      >
                                        <Link
                                          className=""
                                          to={{
                                            pathname: `/customer-details/${item.id}`,
                                            state: { data: item },
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="text-secondary"
                                            width="20"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                          </svg>
                                        </Link>
                                      </OverlayTrigger>
                                      {userData.role == "Admin" && (
                                        <>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Edit</Tooltip>}
                                          >
                                            <Link
                                              className=""
                                              to={{
                                                pathname: `/user-add/edit/${item.id}`,
                                                state: { data: item },
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="text-secondary mx-4"
                                                width="20"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                                />
                                              </svg>
                                            </Link>
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>Delete</Tooltip>}
                                          >
                                            <Button
                                              className="badge bg-danger"
                                              onClick={() =>
                                                handleShow(
                                                  item.id,
                                                  item.firstname,
                                                  item.lastname
                                                )
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                />
                                              </svg>
                                            </Button>
                                          </OverlayTrigger>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Customer;
