import config from "../../config";
import * as api from "../../common/api";
import * as actionTypes from "../../constants/actionTypes";
import * as localstorage from "../../common/localStorage";

const loginSuccess = (type, text) => ({
  type,
  payload: text,
});

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const signout = () => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_ERROR,
  });
  dispatch(
    successMessage(actionTypes.SUCCESS_MESSAGE, "Signed Out Successfully")
  );
};

export const login = (loginDetails) => (dispatch) => {
  const { email, password } = loginDetails;
  const payload = { email, password };
  api
    .post(`${config.BASE_URL}users/login`, payload)
    .then((response) => {
      let { token, message, user } = response.data;
      if (response.status == 200) {
        localstorage.set("token", token);
        localstorage.set("userdata", JSON.stringify(user));
        dispatch(loginSuccess(actionTypes.USER_LOADED, response.data));
        dispatch(successMessage(actionTypes.SUCCESS_MESSAGE, message));
      }
    })
    .catch((error) => {
      console.log("🚀 ~ file: auth.js ~ line 46 ~ login ~ error", error);
      dispatch({
        type: actionTypes.AUTH_ERROR,
      });
      dispatch(
        errorMessage(actionTypes.ERROR_MESSAGE, error.response.data.message)
      );
    });
};
