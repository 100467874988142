const AccountItem = (props) => {
  return (
    <li
      className="simple-item hover"
      onClick={props.onClick}
      data-toggle-extra="tab"
      data-target-extra={`#account-content-${props.id}`}
    >
      {props.number}
      {props.type}
    </li>
  );
};
export default AccountItem;
