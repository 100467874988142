import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const userAdd = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}users`, payload)
    .then((response) => {
      if (response.status == 200) {
        // localstorage.set("userdata", JSON.stringify(payload));
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const getUsers = (payload) => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}users/role/${payload}`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({ type: actionTypes.GET_USERS, payload: response.data.data });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const userDelete = (payload, role) => (dispatch) => {
  ajaxCall
    .remove(`${config.BASE_URL}users`, { id: payload })
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
        dispatch(getUsers(role));
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const userUpdate = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}users/update-user`, payload)
    .then((response) => {
      if (response.status == 200) {
        // localstorage.set("userdata", JSON.stringify(payload));
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
        // dispatch(getUsers(role));
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const resetPassword = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}users/reset-password`, payload)
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const documentUpload = (payload) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}documents`, payload)
    .then((response) => {
      if (response.status == 200) {
        // localstorage.set("userdata", JSON.stringify(payload));
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const documentGet = (payload) => (dispatch) => {
  ajaxCall
    .get(`${config.BASE_URL}documents/${payload}`)
    .then((response) => {
      if (response.status == 200) {
        dispatch({
          type: actionTypes.GET_DOCUMENTS,
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};
