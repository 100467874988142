import React, { useCallback, useEffect, useState } from "react";
// import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";

//leaflet
import Leaflet from "../../../components/leaflet";
import moment from "moment";

//datepicker
import Datepickers from "../../../components/Datepicker";
import { useSelector } from "react-redux";

//circularpro
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import Card from "../../../components/Card";
import { Audio } from "react-loader-spinner";

const ClientDashboard = (props) => {
  const userAccountData = useSelector((state) => state.accountReducer.accounts);
  let sortedAccountDetails = userAccountData;
  sortedAccountDetails.sort((a, b) => {
    return new Date(a.commencement_date) - new Date(b.commencement_date);
  });


  const [fyear, setFyear] = useState(moment().year().toString());

  // const [loading, setLoading] = useState(true);

  const [options, setOptions] = useState({
    chart: {
      id: "apex-basic",
      zoom: {
        enabled: !1,
      },
    },
    colors: ["#4788ff"],
    xaxis: {
      categories: [],
    },
    title: {
      text: "Total Returns",
      align: "left",
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "straight",
    },
    noData: {
      text: "No entries found",
    },
  });

  const [series, setSeries] = useState([
    {
      name: "Total Returns",
      data: [],
    },
    {
      name: "Withdrawal",
      type: "column",
      data: [],
    },
  ]);

  let totalReturnArr = [],
    returnAccount = [],
    totalReturn = 0,
    totalInvested = 0,
    capitalGainArr = [],
    capitalGainObj = {},
    months = [],
    amount = [],
    res = [],
    financialYearArr = [],
    chartObj = {},
    finalChartArr = [],
    debtInvested = 0,
    equityInvested = 0,
    debtCapitalGain = 0,
    equityCapitalGain = 0,
    withdrawalAmountArr = [],
    lablesChart = [],
    seriesChart = [],
    debtReturn = [],
    totalDebtReturn = 0,
    totalEquityReturn = 0,
    equityReturn = [],
    withdrawalMonthArr = [];

  const equityAccounts = [],
    debtAccounts = [];
  let totalDebt = 0,
    totalEquity = 0;
  userAccountData.map((item) => {
    if (item.account_type == "Debt") {
      debtAccounts.push(item);
      totalDebt += item.account_balance;
    } else if (item.account_type == "Equity") {
      equityAccounts.push(item);
      totalEquity += item.account_balance;
    }
  });

  userAccountData &&
    userAccountData.map((item, i) => {
      if (item.account_type == "Debt") {
        debtInvested += item.account_balance;
        debtReturn[i] = item.account_balance;
        item.transactions[i] !== undefined &&
          item.transactions[0] !== null &&
          item.transactions.map((t) => {
            if (t.transaction_type == "Returns") {
              debtReturn[i] += t.transaction_amount;
            }
          });
        totalDebtReturn += debtReturn[i];
      }
      if (item.account_type == "Equity") {
        equityInvested += item.account_balance;
        equityReturn[i] = item.account_balance;
        item.transactions[i] !== undefined &&
          item.transactions[0] !== null &&
          item.transactions.map((t) => {
            if (t.transaction_type == "Returns") {
              equityReturn[i] += t.transaction_amount;
            }
          });
        totalEquityReturn += equityReturn[i];
      }
      totalInvested += item.account_balance;
      returnAccount[i] = item.account_balance;
      item.transactions[i] !== undefined &&
        item.transactions[0] !== null &&
        item.transactions.map((t) => {
          if (t.transaction_type == "Returns") {
            returnAccount[i] += t.transaction_amount;
            capitalGainObj = {
              date: moment(t.transaction_date).format("MMMM yyyy"),
              year: moment(t.transaction_date).format("yyyy"),
              amount: t.transaction_amount,
            };
            capitalGainArr.push(capitalGainObj);
          }
        });
      totalReturn += returnAccount[i];
      totalReturnArr.push(returnAccount[i]);
    });

  financialYearArr = [
    ...new Map(capitalGainArr.map((item) => [item["year"], item])).values(),
  ];

  const map = new Map();
  for (const { date, amount } of capitalGainArr) {
    const currSum = map.get(date) || 0;
    map.set(date, currSum + amount);
  }
  res = Array.from(map, ([date, amount]) => ({
    date,
    amount,
  }));

  // Change chart data based on year
  useEffect(() => {
    res.map((item) => {
      console.log(fyear);
      if (moment(fyear).isSame(item.date, "year")) {
        chartObj = {
          month: moment(item.date).format("MMMM"),
          amount: item.amount,
        };
        finalChartArr.push(chartObj);
      }
    });
    // Sorting the months
    function sortByMonth(arr) {
      var sortMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      arr.sort(function (a, b) {
        return sortMonths.indexOf(a.month) - sortMonths.indexOf(b.month);
      });
    }

    sortByMonth(finalChartArr);

    finalChartArr.map((item) => {
      months.push(item.month);
      amount.push(item.amount);
    });
    setOptions({
      ...options,
      xaxis: {
        categories: months,
      },
    });
    setSeries([
      {
        name: "Total Returns",
        data: amount,
      },
    ]);
  }, [fyear, userAccountData.length]);

  if (debtAccounts.length) {
    lablesChart = ["Debt Invested", "Debt Capital Gain"];
    seriesChart = [debtInvested, totalDebtReturn];
  }
  if (equityAccounts.length) {
    lablesChart = ["Equity Invested", "Equity Capital Gain"];
    seriesChart = [equityInvested, totalEquityReturn];
  }
  if (debtAccounts.length && equityAccounts.length) {
    lablesChart = [
      "Equity Invested",
      "Debt Invested",
      "Debt Capital Gain",
      "Equity Capital Gain",
    ];
    seriesChart = [
      equityInvested,
      debtInvested,
      totalDebtReturn,
      totalEquityReturn,
    ];
  }

  const chart3 = {
    options: {
      chart: {
        height: 330,
        type: "donut",
      },

      labels: lablesChart,
      colors: ["#F6BE9A", "#00A39B", "#004643", "#E16214"],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            size: "80%",
            labels: {
              show: true,
              total: {
                show: false,
                color: "#BCC1C8",
                fontSize: "18px",
                fontFamily: "DM Sans",
                fontWeight: 600,
              },
              value: {
                show: true,
                fontSize: "25px",
                fontFamily: "DM Sans",
                fontWeight: 700,
                color: "#8F9FBC",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          bottom: 0,
        },
      },
      legend: {
        position: "bottom",
        offsetY: 8,
        show: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 268,
            },
          },
        },
      ],
    },
    series: seriesChart,
  };

  return userAccountData.length ? (
    <Container fluid>
      <Row>
        <Col md="12" className="">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="font-weight-bold">Overview</h4>
            <Form.Group as={Row}>
              <Col sm="6">
                <Form.Label>Financial Year</Form.Label>
              </Col>
              <Col sm="6">
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setFyear(e.target.value);
                  }}
                >
                  {financialYearArr &&
                    financialYearArr.map((item) => {
                      return <option value={item.year}>{item.year}</option>;
                    })}
                </Form.Control>
              </Col>
            </Form.Group>
          </div>
        </Col>
        <Col md="12">
          {/* {res.length && ( */}
          <Chart options={options} series={series} type="line" height="350" />
          {/* )} */}
        </Col>
      </Row>
      <Row>
        <Col lg="8" md="12">
          <Row>
            <Col md="4">
              <Card>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-2 text-secondary">Active Accounts</p>
                      <div className="d-flex flex-wrap justify-content-start align-items-center">
                        <h5 className="mb-0 font-weight-bold">
                          {userAccountData.length}
                        </h5>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {!!debtAccounts.length && (
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Debt Investment</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {debtAccounts[0].currency} {totalDebt}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            {!!equityAccounts.length && (
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Equity Investment</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            USD {totalEquity}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            {!!debtAccounts.length && (
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">Debt Capital Gain</p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {totalDebtReturn.toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            {!!equityAccounts.length && (
              <Col md="4">
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <p className="mb-2 text-secondary">
                          Equity Capital Gain
                        </p>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                          <h5 className="mb-0 font-weight-bold">
                            {totalEquityReturn.toFixed(2)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Header.Title>
                    <h4 className="card-title">Account List</h4>
                  </Card.Header.Title>
                </Card.Header>
                <Card.Body>
                  <Table hover>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Account Number</th>
                        <th scope="col">Account Type</th>
                        <th scope="col">Account Status</th>
                        <th scope="col">Invested</th>
                        <th scope="col">Commencement Date</th>
                        <th scope="col">Expiry Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedAccountDetails.map((item, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item.account_number}</td>
                          <td>
                            <p className="mb-0 text-primary d-flex justify-content-start align-items-center">
                              <small>
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="#3378FF"
                                  ></circle>
                                </svg>
                              </small>
                              {item.account_type}
                            </p>
                          </td>
                          <td>
                            <p
                              className={`mb-0 ${
                                item.account_status
                                  ? "text-success"
                                  : "text-danger"
                              } d-flex justify-content-start align-items-center`}
                            >
                              <small>
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill={
                                      item.account_status
                                        ? "#3cb72c"
                                        : "#f42b3d"
                                    }
                                  ></circle>
                                </svg>
                              </small>
                              {item.account_status ? "Active" : "Terminated"}
                            </p>
                          </td>
                          <td>
                            {item.currency} {item.account_balance}
                          </td>
                          <td>{item.commencement_date}</td>
                          <td>{item.termination_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            {/* {debtAccounts.map((item) => (
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Header.Title>
                      <h4 className="card-title">
                        Monthly Returns Report Account : {item.account_number}
                      </h4>
                    </Card.Header.Title>
                  </Card.Header>
                  <Card.Body>
                    <Chart
                      options={chart8.options}
                      series={chart8.series}
                      type="bar"
                      height="350"
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))} */}
          </Row>
        </Col>
        <Col lg="4" md="6">
          <Card>
            <Card.Body>
              <h4 className="font-weight-bold mb-3">Account Analysis</h4>
              <Chart
                className="custom-chart"
                options={chart3.options}
                series={chart3.series}
                type="donut"
                height="330"
              />
              <div className="d-flex justify-content-around align-items-center">
                {debtAccounts.length && (
                  <>
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#ffbb33"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#ffbb33"
                        />
                      </svg>

                      <span>Debt Invested</span>
                    </div>
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#00A39B"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#004643"
                        />
                      </svg>

                      <span>Debt Capital Gain</span>
                    </div>
                  </>
                )}
                {equityAccounts.length && (
                  <>
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#00A39B"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#F6BE9A"
                        />
                      </svg>

                      <span>Equity Invested</span>
                    </div>
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#00A39B"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="18"
                          height="18"
                          rx="2"
                          fill="#E16214"
                        />
                      </svg>

                      <span>Equity Capital Gain</span>
                    </div>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <Audio
      height="80"
      width="80"
      radius="9"
      color="green"
      ariaLabel="three-dots-loading"
      wrapperStyle
      wrapperClass
    />
  );
};

export default ClientDashboard;
