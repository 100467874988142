import config from "../../config";
import * as ajaxCall from "../../common/ajaxCall";
import * as actionTypes from "../../constants/actionTypes";

const successMessage = (type, text) => ({
  type,
  payload: text,
});

const errorMessage = (type, text) => ({
  type,
  payload: text,
});

export const transactionAdd = (payload, type) => (dispatch) => {
  ajaxCall
    .post(`${config.BASE_URL}transactions`, payload)
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
        dispatch(getTransactions(payload.account_id, type));
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};

export const getTransactions =
  (payload, type = "all") =>
  (dispatch) => {
    ajaxCall
      .post(`${config.BASE_URL}transactions/${payload}/${type}`)
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: actionTypes.GET_TRANSACTIONS,
            payload: response.data.data,
          });
          return response.data.data;
        }
      })
      .catch((error) => {
        dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
      });
  };

export const transactionDelete = (payload, account_id, type) => (dispatch) => {
  ajaxCall
    .remove(`${config.BASE_URL}transactions`, { id: payload })
    .then((response) => {
      if (response.status == 200) {
        dispatch(
          successMessage(actionTypes.SUCCESS_MESSAGE, response.data.message)
        );
        dispatch(getTransactions(account_id, type));
      }
    })
    .catch((error) => {
      dispatch(errorMessage(actionTypes.ERROR_MESSAGE, error.data.message));
    });
};
