import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, Tab, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { getTransactions } from "../../../actions/transactionAction/transAction";
import Card from "../../../components/Card";

import Returns from "../Account/Returns";
import Soa from "../Account/Soa";
import AccountAnalysis from "../Account/AccountAnalysis";
import Withdrawal from "../Account/Withdrawal";

const DebtAccount = () => {
  const history = useHistory();
  const location = useLocation();

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const param = useParams();

  const dispatch = useDispatch();
  const [balanceCalc, setBalanceCalc] = useState({
    totalDeposit: 0,
    totalWithdrawal: 0,
    currentBalance: 0,
  });
  const [activeTab, setActiveTab] = useState("all");

  const account = useLocation().state.account;

  const transactionDetails = useSelector(
    (state) => state.transactionReducer.transactions
  );

  useEffect(() => {
    dispatch(getTransactions(param.id, "all"));
  }, [param.id]);

  const handleSelect = (e) => {
    setActiveTab(e);
    e = ["all", "Withdrawal"].includes(e) ? "all" : e;
    // dispatch(getTransactions(param.id, e));
  };

  useEffect(() => {
    // if (activeTab == "all") {
    let td = 0,
      tw = 0,
      cb = 0;
    transactionDetails.map((item) => {
      if (item.transaction_type == "Deposit") {
        td += +item.transaction_amount;
      } else if (item.transaction_type == "Withdrawal") {
        tw += +item.transaction_amount;
      }
    });
    cb = td - tw;
    setBalanceCalc({
      totalDeposit: td,
      totalWithdrawal: tw,
      currentBalance: cb,
    });
    // }
  }, [transactionDetails]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12" className="mb-4 mt-1">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h4 className="font-weight-bold">
                Account : {account.account_number}
              </h4>
              <button
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2"
                onClick={() => history.goBack()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">Back</span>
              </button>
            </div>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body className="p-0">
                <Tab.Container
                  defaultActiveKey="Analysis"
                  onSelect={(e) => handleSelect(e)}
                >
                  <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                    {userData && userData.role == "Customer" && (
                      <Nav.Item as="li" className="pb-2 mb-0 nav-item">
                        <Nav.Link
                          data-toggle="tab"
                          eventKey="Analysis"
                          bsPrefix="font-weight-bold text-uppercase px-5 py-2"
                        >
                          Account Analysis
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <Nav.Item as="li" className="pb-2 mb-0 nav-item">
                      <Nav.Link
                        data-toggle="tab"
                        eventKey="Withdrawal"
                        bsPrefix="font-weight-bold text-uppercase px-5 py-2"
                      >
                        Withdrawal Report
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="pb-2 mb-0 nav-item">
                      <Nav.Link
                        data-toggle="tab"
                        eventKey="Returns"
                        bsPrefix="font-weight-bold text-uppercase px-5 py-2"
                      >
                        Returns Report
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="pb-2 mb-0 nav-item">
                      <Nav.Link
                        data-toggle="tab"
                        eventKey="all"
                        bsPrefix="font-weight-bold text-uppercase px-5 py-2"
                      >
                        SOA
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="Analysis" className="  fade p-3">
                      <AccountAnalysis
                        account={param.id}
                        transactionDetails={transactionDetails}
                        balanceCalc={balanceCalc}
                        accountDetails={account}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Withdrawal" className="  fade p-3">
                      <Withdrawal
                        account={param.id}
                        transactionDetails={transactionDetails}
                        balanceCalc={balanceCalc}
                        accountDetails={account}
                        user={userData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Returns" className="  fade p-3">
                      <Returns
                        account={param.id}
                        transactionDetails={transactionDetails}
                        balanceCalc={balanceCalc}
                        accountDetails={account}
                        user={userData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="all" className="fade p-3">
                      <Soa
                        account={param.id}
                        transactionDetails={transactionDetails}
                        balanceCalc={balanceCalc}
                        accountDetails={account}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DebtAccount;
