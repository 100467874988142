import React, { useState, useEffect } from "react";

import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { transactionAdd } from "../../../actions/transactionAction/transAction";
//datepicker
import Datepickers from "../../../components/Datepicker";

const Profit = (props) => {
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState({
    company_share: 0,
    customer_share: 0,
  });

  const [tempTD, setTempTD] = useState([...props.transactionDetails]);

  useEffect(() => {
    setTempTD(props.transactionDetails);
  }, [props.transactionDetails]);

  const [showForm, setShowForm] = useState(false);

  const handleSubmit = async () => {
    let transaction_type = "Profit_Withdrawal";
    const { customer_share, company_share, transaction_date } = transactionData;
    if (customer_share && company_share && transaction_date) {
      let payload = {
        ...transactionData,
        transaction_type,
        account_id: props.account,
      };
      dispatch(transactionAdd(payload, "Profit_Withdrawal"));
      setShowForm(false);
      setTransactionData({
        company_share: 0,
        customer_share: 0,
      });
    }
  };

  const handleChange = (e) => {
    setTransactionData({
      ...transactionData,
      [e.target.name]: e.target.value,
    });
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateFilter, setDateFilter] = useState(false);

  const { transactionDetails } = props;

  let cs = 0,
    cos = 0;

  transactionDetails.map((item) => {
    cs += +item.customer_share;
    cos += +item.company_share;
  });

  const handleFilter = () => {
    let temp = [...props.transactionDetails];
    if (!dateFilter) {
      temp = props.transactionDetails.filter((item) => {
        return (
          fromDate &&
          moment(new Date(fromDate)) <=
            moment(new Date(item.transaction_date)) &&
          toDate &&
          moment(new Date(toDate)) >= moment(new Date(item.transaction_date))
        );
      });
    }
    setTempTD(temp);
  };

  const filterDate = () => {
    if (fromDate || toDate) {
      setDateFilter(true);
    }
  };

  const {
    accountDetails: { currency, account_balance },
    accountDetails,
  } = props;
  let currencySymbol =
    currency == "USD"
      ? "$"
      : currency == "AED"
      ? "AED"
      : currency == "INR"
      ? "₹"
      : "€";

  return (
    <>
      <div id="table" className="table-editable">
        <span className="table-add float-right mb-3 mr-2">
          <button
            onClick={() => setShowForm(true)}
            className="btn btn-sm bg-primary"
          >
            <i className="ri-add-fill">
              <span className="pl-1">Add New</span>
            </i>
          </button>
        </span>
        <div
          className="form-group vanila-daterangepicker d-flex flex-row"
          id="date_filter"
          style={{ marginTop: "3%", marginBottom: "3%" }}
        >
          <div className="date-icon-set">
            <Datepickers
              className="vanila-datepicker"
              names="start"
              placeholder="From Date"
              value={fromDate}
              onBlur={(e) => setFromDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span className="flex-grow-0">
            <span className="btn">To</span>
          </span>
          <div className="date-icon-set">
            <Datepickers
              names="end"
              placeholder="To Date"
              value={toDate}
              onBlur={(e) => setToDate(e.target.value)}
            />
            <span className="search-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                width="20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
          </div>
          <span
            className="table-add float-right mb-3 mr-2"
            style={{
              marginLeft: "30px",
            }}
          >
            {!dateFilter ? (
              <button
                onClick={() => {
                  filterDate();
                  handleFilter();
                }}
                className="btn btn-sm bg-warning"
              >
                <span className="pl-1">Filter</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  setDateFilter(false);
                  setToDate("");
                  setFromDate("");
                  handleFilter();
                }}
                className="btn btn-sm bg-success"
              >
                <span className="pl-1">Reset</span>
              </button>
            )}
          </span>
        </div>
        <table className="table table-bordered table-responsive-md table-striped text-center">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>No.</th>
              <th>Date\Time</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Customer Share</th>
              <th>Company Share</th>
            </tr>
          </thead>
          <tbody>
            {tempTD &&
              tempTD.map((item, index) => (
                <tr>
                  <td name="No">{index + 1}</td>

                  <td name="Date">{item.transaction_date}</td>
                  <td name="Currency">{currency}</td>
                  <td name="Amount">
                    {currencySymbol}{" "}
                    {+item.customer_share + +item.company_share}
                  </td>
                  <td name="Customer">
                    {currencySymbol} {item.customer_share}
                  </td>
                  <td name="Company">
                    {currencySymbol} {item.company_share}
                  </td>
                </tr>
              ))}
            {showForm && (
              <tr>
                <td>
                  <button
                    className="btn btn-success position-relative d-flex align-items-center justify-content-between"
                    onClick={() => handleSubmit()}
                  >
                    Add
                  </button>
                </td>
                <td>
                  <input
                    type="date"
                    name="transaction_date"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={currency}
                    className="form-control"
                    disabled
                  />
                </td>
                <td>
                  {currencySymbol}{" "}
                  {+transactionData.customer_share +
                    +transactionData.company_share}
                </td>
                <td>
                  <input
                    type="number"
                    name="customer_share"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="company_share"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="table-responsive">
        <table
          id="datatable"
          className="table data-table table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>Total Customer Amount</th>
              <th>Total Company Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {currencySymbol} {cs}
              </td>
              <td>
                {currencySymbol} {cos}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Profit;
